import React from 'react';
import Bike from './bike';
//import './Dashboard.css';
import { Text } from '@fluentui/react';
import Holiday from './holiday';
import Fibu from './Fibu';

class Dashboard extends React.Component {
  render() {
    return (
      <div className="component dashboard">
        <div className="main-view">
          <h1>Dashboard</h1>
          <div className="content">
                <div className="box">
                  <Bike mode='dashboard' />
                </div>
                <div className="box">
                  <Holiday mode='dashboard' />
                </div>
                <div className="box">
                  <Fibu
                    mode='dashboard'
                    dashboard={{
                      type:'UeberschussLfd'
                    }}
                  />
                </div>
                <div className="box">
                  <Fibu
                    mode='dashboard'
                    dashboard={{
                      type:'Ausgabe30days'
                    }}
                  />
                </div>
          </div>         
        </div>
      </div>
    )
  }
}

export default Dashboard;  