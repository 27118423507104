import React from 'react';
import './Login.css';
import { getTheme,PrimaryButton } from '@fluentui/react';

class Login extends React.Component<{
  onLogin:(username:string, password:string)=>void
}> {
  
  render() {

    return (
      <div id="login-view">
      <form className="wk-form wk-form-stacked" style={{background:getTheme().palette.themeDark}}>
          <input type="hidden" name="do" value="Login" />

          <label>Benutzername</label>
          <input type="text" maxLength={25} name="username" id="username" required />

          <label>Passwort</label>
          <input type="password" maxLength={25} name="passwort" id="passwort" required />

          <PrimaryButton onClick={(e:React.MouseEvent<HTMLElement>)=>this.handleLoginClick(e)} >Anmelden</PrimaryButton>
      </form>
      </div>
    )
  }

  handleLoginClick(e:React.MouseEvent<HTMLElement>){
    e.preventDefault();
    var username = document.getElementById('username') as HTMLInputElement;
    var passwort = document.getElementById('passwort') as HTMLInputElement;
    if (username.value === ''){
      alert("Bitte Nutzernamen eingeben");
      username.focus();
      return false;
    } 
    if (passwort.value === ''){
      alert("Bitte Passwort eingeben");
      passwort.focus();
      return false;
    }
    this.props.onLogin(username.value,passwort.value);
  }
}

export default Login;