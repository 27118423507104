import React from 'react';
import { API , ListView} from '../common';

import { Selection, SelectionMode, CommandBar, SearchBox, Shimmer, Toggle, PrimaryButton, DefaultButton, IconButton, Panel, PanelType, Dropdown, TextField, IDropdownOption } from '@fluentui/react';
import { Blowfish } from 'javascript-blowfish';
import { copyTextToClipboard } from '../common/libery'
 

import './passwords.css';

const PasswordsConfig = {
  apiBase:  '/passwords',
}
interface PasswordProps {

}
interface PasswordState {
  items:any[],
  kategories:any[],
  sort:any,
  showPasswords:boolean,
  showItem:boolean,
  workItem:any,
  selectedItem:any,
  search:string,
  filterkategorie:string,
  keyList:string[]
}
const defaultPasswortItem={id:0,kategorie:'',titel:'',url:'',login:'',notizen:''}
class Passwords extends React.Component<PasswordProps,PasswordState> {

  _selection: Selection;

  constructor(props:PasswordProps){
    super(props);

    this.state = {
      items: [],
      kategories:[],
      sort:{by:'id',desc:false},
      showPasswords:false,
      showItem:false,
      workItem:false,
      selectedItem:false,
      search:'',
      filterkategorie:'',
      keyList:[
        '5G*gfoYgipJ^P{6IAKUNJt(+gBpn9iP|1^sXbUtU|==z3~De##+;?KIl-l9',
        'odY9h&"#s4+~r;NC,q5QROl/{HK9@>0-aUgg%>uf[.[2R5k({p-Fe/zU.E+a!AE',
        'ewk^cT09-i:9ahKWmT-L6ok-[hu&yQ5ym?ucup`f7O5EKi3y#"OCKTL}&.M`~ax'
      ]
    }
    
    this._selection = new Selection({
      onSelectionChanged: () => {
        let items:any[]=this._selection.getSelection();
        if (items.length > 0 )
          this.getItem(items[0].id)
      }
    });
  }
  passwordDecrypt(pwd:string){

    return pwd;
    let key=this.state.keyList[parseInt(pwd.substr(0,1),10)];
    var bf = new Blowfish(key);
    return bf.trimZeros(bf.decrypt(pwd.substr(2)));
  }
  passwordEncrypt(pwd:string){
      return pwd;
      let index = Math.round(Math.random()*100000)%this.state.keyList.length; 
      let key=this.state.keyList[index];
      
      var bf = new Blowfish(key);
      return index+':'+bf.encrypt(pwd);
  }
  
  getItem(id:number){
    console.debug(id); console.log(new Error().stack); 
    if (id >=0 ){
      API.get(PasswordsConfig.apiBase+'/'+id )
        .then( (result) => {
          
          if (result.pwd.substr(1,1)===':'){
            if (result.pwd.substr(0,1).match('[0-9]')){
                result.pwd = this.passwordDecrypt(result.pwd);
            }
          }
          /** */
          this.setState({
            workItem: result,
            selectedItem:result
          }); 
      }).catch( (error) => {
        console.error(error);
      });
    } else {
      this._selection.setAllSelected(false)
      this.setState({
        workItem: false,
        items : JSON.parse(JSON.stringify(this.state.items)),
        selectedItem: false
      }); 

    }
  }
  _getItems(){ 
    let desc=this.state.sort.desc? '+desc':'';
    API.get(PasswordsConfig.apiBase+'?search='+this.state.search+'&order='+this.state.sort.by +desc+'&kategorie='+this.state.filterkategorie )
      .then( (result) => {
        for(let j=0; j<result.length; j++){
          
            if (result[j].pwd.substr(1,1)===':'){
              if (result[j].pwd.substr(0,1).match('[0-9]')){
                result[j].pwd = this.passwordDecrypt(result[j].pwd);
              }
          }
        }
        this.setState({
          items: result
        }); 
    }).catch( (error) => {
      console.error(error);
    });
  }


  componentDidMount(){
    
    API.get(PasswordsConfig.apiBase+'/kategories' )
      .then( (result) => {
        this.setState({
          kategories: result
        }); 
    }).catch( (error) => {
      console.error(error);
    });
    if (!this.state.items.length) this._getItems();
  } 

  closeItem(){
    this._selection.setAllSelected(false)
    this.setState({ workItem: false });
  }

  render() {
    const comandbarbottons = [
			{
				key: 'add',
				name: 'Neues Passwort',
				iconProps: {
					iconName: 'circlePlus'
				},
				onClick: ()=>{
					this.setState({
						showItem : true,
						selectedItem : defaultPasswortItem,
						workItem : JSON.parse(JSON.stringify(defaultPasswortItem)),
					});
		
				}
			}
    ];
    
		const comandbarbottons2 = [
			{
				key: 'search',
				onRender: () => {
					return (
						<div style={{width: '200px', alignSelf: 'center'}}>
							<SearchBox
								placeholder="Passwort  suchen"
								value={this.state.search}
								onChange={ (e,newValue) => { 
                  this.setState({search:newValue||''},()=>this._getItems())
                }}
							/>
						</div>
					);
				}
			},{
				key: 'filter',
				onRender: () => {
					return (
						<div style={{padding: '15px', alignSelf: 'center'}}>
            <Toggle checked={this.state.showPasswords} onChange={(event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean ) =>{this.setState({showPasswords:checked!})}} onText="Passwörter anzeigen" offText="Passwörter verbergen" />
						</div>
					);
        }
      },
      {
				key: 'filterkategorie',
				onRender: () => {
          let items = [{key:"",text:"alle Kategorien"}];
          this.state.kategories.map((value)=>items.push(value));
					return (
            <Dropdown
            options={items}
            selectedKey={this.state.filterkategorie} 
          
            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined)=>{
               this.setState({filterkategorie:String(option?.key||'')},()=>this._getItems());
            }}
        />
					);
        }
      },
      
		];
    return (
      <div className="Passwords component">
        <div className="main-view">
        <h1>Passwörter</h1>
        { window.innerWidth > 720 ? ( 
          <>
					<CommandBar
						items={comandbarbottons}
						farItems={comandbarbottons2}
					/>

<div className="ScrollFixedHead">
					<ListView
						items={this.state.items}
						compact={true}
						columns={ [
              {
                key: 'column1',
                name: 'ID',
                fieldName: 'id',
                minWidth: 50,
                maxWidth: 100,
                isResizable: true,
                isSorted: (this.state.sort.by === 'id'),
                isSortedDescending: (this.state.sort.desc),
                isPadded: true
              },
              {
                key: 'column2',
                name: 'Kategorie',
                fieldName: 'kategorie',
                minWidth: 120,
                maxWidth: 200,
                isResizable: true,
                isSorted: (this.state.sort.by === 'kategorie'),
                isSortedDescending: (this.state.sort.desc),
                isPadded: true
              } ,
              {
                key: 'column3',
                name: 'Titel',
                fieldName: 'titel',
                minWidth: 120,
                maxWidth:400,
                isResizable: true,
                isSorted: (this.state.sort.by === 'titel'),
                isSortedDescending: (this.state.sort.desc),
                isPadded: true
              } ,
              {
                key: 'column4',
                name: 'Login',
                fieldName: 'login',
                minWidth: 180,
                isResizable: true,
                isSorted: (this.state.sort.by === 'login'),
                isSortedDescending: (this.state.sort.desc),
                isPadded: true,
                onRender:(item:any)=> {
                  return (<span>
                    Username: {item.login}<br />
                 
                {this.state.showPasswords &&
                 <span style={{display:'block'}}>Passwort:{item.pwd}</span>
                }
                            
                    {item.notizen}</span>)
                }
              } 
            ]}
            selection={this._selection}
						selectionMode={SelectionMode.single}
						onColumnHeaderClick={(event:any, IColumn:any) => {
							this.setState({
								sort: {
									by: IColumn.fieldName,
									desc: IColumn.fieldName === this.state.sort.by ? !IColumn.isSortedDescending : false
								}
							}, () => {
								this._getItems();
							});
						}}
					/>
          </div>
          </>
        ) : 
          <div>
          {comandbarbottons2.map((value)=>{
            return value.onRender();
          })
          }
          {comandbarbottons.map((value)=>{
            return (<PrimaryButton
            onClick={value.onClick}
            style={{ marginRight: '8px' }}
            text={value.name} />)
          })
          }
          {this.state.items.map((item)=>{
            return (<div style={{borderTop:'1px solid #ddd', marginTop: '1em'}}><div>
                <h2>#{item.id}:{item.titel}</h2>
                Kategorie: {item.kategorie}<br />
                Username: {item.login}</div>
                {this.state.showPasswords &&
                 <div style={{display:'inline-block'}}>Passwort:{item.pwd}</div>
                }
                        
               <div> {item.notizen}</div><PrimaryButton
            onClick={()=>{ this.getItem(item.id)}}
            style={{ marginRight: '8px' }}
            text="Ändern" />
            </div>)
          })
          }

          </div>
        }
      	<Panel
          isOpen={this.state.workItem!==false}
          type={PanelType.smallFixedFar}
          headerText={"Passwortmaske"}
          onDismiss={()=>this.closeItem()}
          closeButtonAriaLabel="Close"  
          onRenderFooterContent={() => {
            const loeschen = (this.state.workItem.id > 0 ? 
              <DefaultButton
                onClick={()=>this.closeItem()}
                style={{ marginRight: '8px' }}
                text="löschen" />
                : '')
            return JSON.stringify(this.state.workItem) !== JSON.stringify(this.state.selectedItem) ?
            (
              <div>
                <PrimaryButton
                  onClick={()=>{

                    let data = {...this.state.workItem};
                    data.pwd = this.passwordEncrypt(data.pwd);

                    API.patch(PasswordsConfig.apiBase,data)
                    .then( (result) => {
                        this._getItems();
                        this.closeItem();
                    }).catch( (error) => {
                        console.error(error);
                    });
                  }}
                  style={{ marginRight: '8px' }}
                  text="Speichern" />
                  <DefaultButton
                    onClick={()=>this.closeItem()}
                    style={{ marginRight: '8px' }}
                    text="Verwerfen & Schließen" />
              </div>
            )
            :(
              <div>
                <PrimaryButton
                  onClick={()=>this.closeItem()}
                  style={{ marginRight: '8px' }}
                  text="Schließen" />
                  {loeschen}
              </div>
            );
          }}
        >   

        <div className="feld-container office-ui">
        <Dropdown  label="Kategorie" 
            options={this.state.kategories}
            selectedKey={this.state.workItem.kategorie} 
          
            onChange={(e,option)=>{
              var clone = {...this.state.workItem};
              clone.kategorie = option?.key;
              this.setState({workItem:clone});
            }}
        />
        <TextField type="text" label="Titel" value={this.state.workItem.titel} 
          
          onChange={(e)=>{
            var clone = {...this.state.workItem};
            clone.titel =  (e.target as HTMLInputElement).value;
            this.setState({workItem:clone});
          }}
        />
        <div className='copyField'>
        <TextField type="text" label="Login-Seite" value={this.state.workItem.url} 
          
          onChange={(e)=>{
            var clone = {...this.state.workItem};
            clone.url = (e.target as HTMLInputElement).value;
            this.setState({workItem:clone});
          }}
        /> 
        <IconButton iconProps={{iconName:'Copy'}} onClick={()=>copyTextToClipboard(this.state.workItem.url)} />
        </div>
        <div className='copyField'>        
        <TextField type="text" label="Usersname" value={this.state.workItem.login} 
          
          onChange={(e)=>{
            var clone = {...this.state.workItem};
            clone.login = (e.target as HTMLInputElement).value;
            this.setState({workItem:clone});
          }}
        />
        <IconButton iconProps={{iconName:'Copy'}} onClick={()=>copyTextToClipboard(this.state.workItem.login)} />
        </div>
        <div className='copyField'>
        <TextField type="text" label="Password" value={this.state.workItem.pwd} 
          
          onChange={(e)=>{
            var clone = {...this.state.workItem};
            clone.pwd = (e.target as HTMLInputElement).value;
            this.setState({workItem:clone});
          }}
        />
        <IconButton iconProps={{iconName:'Copy'}} onClick={()=>copyTextToClipboard(this.state.workItem.pwd)} />
        </div>
        <TextField type="text" multiline label="Notizen" value={this.state.workItem.notizen} 
          
          onChange={(e)=>{
            var clone = {...this.state.workItem};
            clone.notizen = (e.target as HTMLInputElement).value;
            this.setState({workItem:clone});
          }}
        />
        </div>
                  
          </Panel>

        </div>
      </div>
    );
    
  }

  
}

export default Passwords;