import React from 'react';

import { ScrollablePane, DetailsList, SelectionMode ,DetailsListLayoutMode,  ConstrainMode, mergeStyleSets, Sticky, StickyPositionType, MarqueeSelection } from '@fluentui/react';

export class ListView extends React.Component {
	 
	render() {
		
		const classNames = mergeStyleSets({
			wrapper: {
			  flexGrow:1,
              position: 'relative',
              height:'100%',
			},
			filter: {
			  paddingBottom: 20,
			  maxWidth: 300
			},
			header: {
			  margin: 0
			},
			row: {
			  display: 'inline-block'
			},
			sticky:{
			  fontWeight:'bold',
			  position:'sticky'
			}
		  });
		return (
		<div  className={classNames.wrapper} >

    <ScrollablePane >
      <MarqueeSelection selection={this.props.selection}>
        <DetailsList
          compact={true}
          
          layoutMode={DetailsListLayoutMode.fixedColumns}
          constrainMode={ConstrainMode.unconstrained}
          selectionMode={SelectionMode.none}
          onRenderDetailsHeader={(props, defaultRender)=> {
            return (
              <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                {defaultRender({
					...props,
					styles: {
						root: {
							selectors: {
								'.ms-DetailsHeader-cell': {
									whiteSpace: 'normal',
									textOverflow: 'clip',
									lineHeight: 'normal',
								},
								'.ms-DetailsHeader-cellTitle': {
									height: '100%',
									alignItems: 'center',
								},
							},
						},
					} 
				})}
              </Sticky>
            );
          }}
          
          {...this.props}
        />
      </MarqueeSelection>
      {this.props.footer}
    </ScrollablePane>
   </div>);
	}
}