import {  DatePicker, DefaultButton, IconButton, Icon, Pivot, PivotItem, Text,Selection, mergeStyleSets, getTheme, Toggle } from '@fluentui/react';
import React from 'react';

import { API, ListView,Libery, DBNavigator, AccordionItem, Accordion } from '../common';
import { blankBuchung,blankVorschauBuchung, Buchung, IBuchung } from './fibu/buchung';
import { KontoBestaende, KontoListe } from './fibu/konten';
import { AusgabeInDays, Auswertung, MaxHistory, MaxHistoryItem, Ueberschuss, UeberschussType } from './fibu/auswertung';
import config from '../config';
import { Kreditrechner } from './fibu/rerchner';
import { Umlauf } from './fibu/umlauf';
import { PreviewList } from './fibu/preview';
import { Barerfassung, IBarerfassung } from './fibu/Barerfassung';
import Boerse from './Boerse';
const Config = {
  apiBase:  '/fibu',
}
export interface IFibuProps {
  mode?:string,
  dashboard?:{
    type:string
  }
}
export interface IFibuState {
  konten:any[],
  bestandskonten:any[],
  kontotypen:any[],
  kontoTyp:string,
  umlauf:any[],
  buchungItem:IBuchung,
  DefBuchungItem:IBuchung,
  vorschauBuchungIndex:number,
  vorschauBuchungen:IBuchung[],
  count:number,
  previewItems:any[],
  MaxHistoryItems:MaxHistoryItem[],
  vorschauVon:Date,
  vorschauBis:Date,
  tab:string,
  showBuchungsform:boolean
}
interface IFibuPrivate {
  _selection: Selection
}


export default class Fibu extends React.Component<IFibuProps,IFibuState,IFibuPrivate> {

  public _selection: Selection
  constructor(props:any){
    super(props);
    this._selection =  new Selection({
      onSelectionChanged:()=>{
        let I:any[]=(this._selection.getSelection())
        if (I.length > 0){
          let B:any = JSON.parse(JSON.stringify(I[0]))
          delete B?.konten;
          B.prevID = B.ID;
          delete B.ID;
          delete B.tage;
          
          this.setState({buchungItem:{...blankBuchung,...B},tab:'buchen'},()=>this._selection.setAllSelected(false))
        }
      }
    })
    
    let vorschauBis:Date = (new Date());
    vorschauBis.setDate(vorschauBis.getDate()+10)
    this.state = {
      konten:[],
      bestandskonten:[],
      kontotypen:[],
      umlauf:[],
      kontoTyp : 'geldKonten',
      buchungItem: blankBuchung,
      DefBuchungItem: blankBuchung,
      vorschauBuchungen:[],
      vorschauBuchungIndex:0,
      previewItems:[],
      MaxHistoryItems:[],
      count:0,
      vorschauVon:new Date,
      vorschauBis:vorschauBis,
      tab:'buchen',
      showBuchungsform: window.innerWidth > 800
    }
  }
  getKonten(){
    return API.get( Config.apiBase + '/table/konten_mit_pfad').then(res=>{
      res = res.map((a:any)=>{
        a.Bezeichnung = String(a?.Bezeichnung.charAt(0)).toUpperCase()+a?.Bezeichnung.slice(1);
        
        a.text = a.Bezeichnung + ' ('+a.Gruppe+')'
        return a;
      }).sort((a:any,b:any) => {
        return a.Bezeichnung > b.Bezeichnung ? 1 :( a.Bezeichnung < b.Bezeichnung ? -1 :0)
      })
      this.setState({
        konten : res
      })
    })
  }
  getUmlauf(){
    return API.get( Config.apiBase + '/table/umlauf').then(res=>{
      res = res;
      this.setState({
        umlauf : res,
        count: this.state.count + 1
      })
    })
  }
  getMayHistory(){
    
    API.post( '/fibu/query', {sql:'#MaxHistory#'}).then(res=>{
      let Items:MaxHistoryItem[] = [];
      res.forEach((I:MaxHistoryItem) => {
        if (Items.length > 0 ) {
          if (Items[Items.length-1].Summe < I.Summe) {
            Items.push(I)
          }
        } else {
          Items.push(I)
        }
      })
      this.setState({MaxHistoryItems:Items})
    })

  }
  getVorschaubuchungen(){
    let Data = {
      before:Math.floor((this.state.vorschauVon.valueOf()-(new Date).valueOf())/(24*60*60*1000))+1,
      after: Math.floor((this.state.vorschauBis.valueOf()-(new Date).valueOf())/(24*60*60*1000))+1,
    }
    return API.get( Config.apiBase + '/table/preview').then(res=>{
      this.setState({
        vorschauBuchungen : res,
        vorschauBuchungIndex: Math.min(this.state.vorschauBuchungIndex, res?.length)
      },()=>API.post( Config.apiBase + '/preview',Data).then(res=>{
        this.setState({
          previewItems : res
        })
      })
      )
    })
  }
  componentDidMount(){
    
    API.get( Config.apiBase + '/config/kontotypen').then(res=>{
      this.setState( {kontotypen:res},()=>{
        this.getVorschaubuchungen();
      })
    })
    this.getKonten();
    this.getUmlauf();
    this.getMayHistory()
  } 
  render(): React.ReactNode {
    if (this.props.mode === 'dashboard'){
      const elements = {
        UeberschussLfd:<>
          <h2><Text variant='xxLarge'>Überschuss</Text></h2>
          <Ueberschuss type={UeberschussType.lfdM} />
          <Ueberschuss type={UeberschussType.lfdY} />
          <Ueberschuss type={UeberschussType.prevM} />
          <Ueberschuss type={UeberschussType.prevY} />
        </>,
        Ausgabe30days:<>
          <h2><Text  variant='xxLarge' >Ausgabe nä. 30 Tage</Text></h2>
          <AusgabeInDays after={30} before={0}/>
        </>
      };
      let rt:any=JSON.stringify(this.props.dashboard);
      for (let i in elements) {
        if (i === this.props.dashboard?.type){
          let str = i as keyof typeof elements;
          rt = elements[str];
        }
      }
      return rt
    }
    return this.base()
  }
  base():React.ReactNode {
    
    let mainPivot:any[] = [
      {
        itemProps:{key:"buchen",itemKey:"buchen",headerText:"Buchen"},
        content: <div className="wk-grid wk-u-1 wk-u-l-1-2 space flex-grow">
                {window.innerWidth < 800 && <Toggle 
                                              offText='Buchungsformular' 
                                              onText='Buchungsformular' 
                                              checked={this.state.showBuchungsform}
                                              onChange={(e: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined)=>this.setState({showBuchungsform:checked===true})}
                                            />}
                {(window.innerWidth >= 800 || this.state.showBuchungsform) &&
                <Buchung
                  className=' wk-u-xl-1-3'
                  parent={this}
                  geldKonten={this.state.konten.filter((a:any)=>a.Aktiv === 1).filter((i:any)=>i.Gruppe?.indexOf('Haushalt')===-1)||[]}
                  haushaltsKonten={this.state.konten.filter((a:any)=>a.Aktiv === 1).filter((i:any)=>i.Gruppe?.indexOf('Haushalt')!==-1)||[]}
                  item={this.state.buchungItem}
                  defaultItem={blankBuchung}
                  type="buchen"
                  onSave={()=>{
                    API.post(Config.apiBase+'/buchen',this.state.buchungItem)
                      .then((result:any)=>{
                        this.getKonten();
                        this.setState({buchungItem:blankBuchung, count: this.state.count+1})
                      })
                  }}
                />}
                <Pivot 
                    className="tabs flex-column flex-grow"
                    style={{minHeight:'80vh'}}
                >
                  <PivotItem key={'K'} headerText='Kontobestände'>
                    <KontoBestaende 
                      EchtzeitInput={[
                        {
                          ID:config.FiBu.BargeldKonto,
                          wert:this.state.umlauf.reduce((sum:any,item:any)=>sum+item.Menge*item.Basis,0)
                        }
                      ]}
                      count={this.state.count} 
                      konten={this.state.konten}
                      onLoad={(konten:any[])=>this.setState({bestandskonten:konten})}
                      onZinsenBuchen={(buchung:IBuchung)=>this.setState({buchungItem:buchung})}
                    />

                  </PivotItem>
                  <PivotItem key={'V'} headerText='Vorschau'>         
                      <PreviewList parent={this} />
                  </PivotItem> 
                  <PivotItem key={'M'} headerText='MaxHistory'>         
                      <MaxHistory
                        count={this.state.count}
                        items={[...this.state.MaxHistoryItems]}
                      />
                  </PivotItem>
                  <PivotItem key={'U'} headerText='Umlauf'>         
                      <Umlauf
                        count={this.state.count}
                        umlauf={this.state.umlauf}
                        onSave={(item:any)=>{
                          if (item?.ID > 0) {
                            API
                            .patch(Config.apiBase + '/table/umlauf',item)
                            .then(resilt => this.getUmlauf())
                          } else {
                            API
                            .post(Config.apiBase + '/table/umlauf',item)
                            .then(resilt => this.getUmlauf())
                          }
                        }}

                      />
                  </PivotItem>
                </Pivot>
              </div>
      },
      {
        itemProps:{key:"vorschaub",itemKey:"vorschaub",headerText:"Vorschaubuchungen"},
        content: <div className="wk-grid wk-u-1 wk-u-m-1-2 space flex-grow">
        {window.innerWidth < 800 && <Toggle 
                                      offText='Buchungsformular' 
                                      onText='Buchungsformular' 
                                      checked={this.state.showBuchungsform}
                                      onChange={(e: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined)=>this.setState({showBuchungsform:checked===true})}
                                    />}
        {(window.innerWidth >= 800 || this.state.showBuchungsform) &&
                  <div className='wk-u-l-1-3'>
                    <div>
                      <DefaultButton text='neue Vorschaubuchung' onClick={()=>this.setState({buchungItem:blankVorschauBuchung})} />
                      <DBNavigator 
                        index={this.state.vorschauBuchungIndex}
                        length={this.state.vorschauBuchungen.length}
                        onSetIndex={(index:number)=>this.setState({vorschauBuchungIndex:index},
                          ()=>this.setState({buchungItem:this.state.vorschauBuchungen[this.state.vorschauBuchungIndex],
                            DefBuchungItem:this.state.vorschauBuchungen[this.state.vorschauBuchungIndex]})
                        )}
                      />
                    </div>
                    <Buchung
                      parent={this}
                      geldKonten={this.state.konten.filter((a:any)=>a.Aktiv === 1).filter((i:any)=>i.Gruppe?.indexOf('Haushalt')===-1)||[]}
                      haushaltsKonten={this.state.konten.filter((a:any)=>a.Aktiv === 1).filter((i:any)=>i.Gruppe?.indexOf('Haushalt')!==-1)||[]}
                      item={this.state.buchungItem}
                      defaultItem={this.state.DefBuchungItem}
                      type="vorschau"
                      onSave={()=>{
                        if ((this.state.buchungItem?.ID || 0) > 0) {
                          API
                          .patch(Config.apiBase + '/table/preview',this.state.buchungItem)
                          .then(resilt => this.getVorschaubuchungen())
                        } else {
                          API
                          .post(Config.apiBase + '/table/preview',this.state.buchungItem)
                          .then(resilt => this.getVorschaubuchungen())
                        }
                      }}
                    />
                  </div>}
                  <div className='wk-u-l-2-3 flex-column' style={{position:'relative'}}>
                    <PreviewList parent={this} />

                  </div>
                </div>
      },
      {
        itemProps:{key:"Boerse",itemKey:"Boerse",headerText:"Böerse"},
        content:
            <Boerse />
      },
      {
        itemProps:{key:"Konten",itemKey:"konten",headerText:"Konten"},
        content:
            <KontoListe 
              konten={this.state.konten||[]}
              kontotypen={this.state.kontotypen||[]}
              onSave={(item:any)=>{
                if (item?.ID > 0) {
                  API
                  .patch(Config.apiBase + '/table/konten',item)
                  .then(resilt => this.getKonten())
                } else {
                  API
                  .post(Config.apiBase + '/table/konten',item)
                  .then(resilt => this.getKonten())
                }
              }}
            />
      },
      {
        itemProps:{key:"Bar-Erfassung",itemKey:"Bar-Erfassung",headerText:"Bar-Erfassung"},
        content:
            <Barerfassung
              onBuchen={(item:IBarerfassung)=>{
                let B:IBuchung = {...blankBuchung};
                B.Datum = item.datum || B.Datum,
                B.Betrag = item.betrag
                B.Text = item.text;
                this.setState({buchungItem:{...blankBuchung,...B},tab:'buchen'},()=>this._selection.setAllSelected(false))
              }}
            />
      },
      {
        itemProps:{key:"Auswertungen",itemKey:"Auswertungen",headerText:"Auswertungen"},
        content: 
        <Auswertung
              geldKonten={this.state.konten.filter((i:any)=>i.Gruppe?.indexOf('Haushalt')===-1)||[]}
              haushaltsKonten={this.state.konten.filter((i:any)=>i.Gruppe?.indexOf('Haushalt')!==-1)||[]} 
              count={this.state.count} 
        />
      },{
        itemProps:{key:"Rechner",itemKey:"rechner",headerText:"Rechner",className:'flex-grow'},
        content: 
          <Kreditrechner 
            konten={this.state.konten.filter((i:any)=>i.Gruppe?.indexOf('Geldleihe')>=0)||[]}
          />
      }
    ]

    return (<>
      <div className="Fibu component">
        <div className="main-view"        >
          
       <div style={{position:'relative', flexGrow:1}} className="flex-column">
        {window.innerWidth > 800?
          <Pivot 
            onLinkClick={(item?:PivotItem|undefined)=>{
              let newBuchung:IBuchung = blankBuchung;
              if (item?.props?.itemKey === "vorschaub") {
                if (this.state.vorschauBuchungen.length > this.state.vorschauBuchungIndex) {
                  newBuchung = this.state.vorschauBuchungen[this.state.vorschauBuchungIndex]
                }
              }
              this.setState({buchungItem: newBuchung,DefBuchungItem:newBuchung, tab:item?.props.itemKey || 'buchen'})
            }}
            defaultSelectedKey='vorschaub'
            selectedKey={this.state.tab}
            className="tabs flex-column flex-grow"
          >
          {mainPivot.map((item:any) =>{
          return <PivotItem
           {...item?.itemProps}
           > <div className='flex-grow flex-column' style={{position:'relative'}}>
            	{item.content}
              </div>
           </PivotItem>})}


            
          </Pivot>:<Accordion
            open={[this.state.tab]}
            headerClick={(item:any)=>{
              this.setState({tab:this.state.tab !== item ? item : ''})
            }}
          >
          
              {mainPivot.map((item:any) =>{
              return <AccordionItem
              {...item?.itemProps}
              style={{minHeight:item.itemProps.itemKey === this.state.tab?'80vh':''}}
              >   {item.itemProps.itemKey === this.state.tab ? item.content : <></>}
              </AccordionItem>})}
          
          </Accordion>}
          </div>
      </div>
      </div>
    </>
    );
    
  }
 
}

