import React from 'react';

import './DitgitField.css';

interface DitgitFieldState {
	value : number
}
interface DitgitFieldProps {
	value : number,
	percison? : number,
	onChange:(value:number) => void
}

export class DitgitField extends React.Component<DitgitFieldProps,DitgitFieldState> {
	constructor(props:DitgitFieldProps) {
		super(props);

		this.state = {
			value: 0
		}
	}

	static getDerivedStateFromProps(nextProps:DitgitFieldProps, prevState:DitgitFieldState){
		if (prevState.value !== nextProps.value) {
			return { value: nextProps.value };
		}

		return null;
	}
	change(value:string, faktor:number=1){
		var newValue:number = parseFloat(value)*faktor + this.state.value;
		newValue = Math.round(newValue*100)/100;
		this.setState({
			value : newValue
		});
		this.props.onChange(newValue);
	}


	render() {
		var value = [];
		var tmpvalue = this.state.value+"";
		for (var i = 0, c= tmpvalue.length; i<c; i++){
			if (tmpvalue.substr(i,1)==='.')
				value.push(<span key='komma' className="komma">,</span>)
			else
				value.push(<span key={i}>{tmpvalue.substr(i,1)}</span>)
		}
		var potenzen = []
		for (let i=(this.props.percison||2)*-1; i<10;i++){
			if(Math.pow(10,i) <= this.state.value) {
				potenzen.push(Math.pow(10,i))
			}
		}
		let newValue:number = this.state.value+0.0000;
		
		newValue=parseFloat(String(newValue));
		newValue=Math.round(newValue * Math.pow(10, (this.props.percison||2) )) /  Math.pow(10, (this.props.percison||2) ); 
		
		let result:any[]=[]		
		potenzen.reverse().forEach((potenz,index) => {
			if (newValue > potenz  || this.state.value>potenz || potenz<=1){
				let res = Math.floor(newValue/potenz);
				if (res === -1 ){
					res = 9;
				}
				newValue -=res*potenz;
				newValue = Math.round(newValue*1000)/1000;


				
			
				if (potenz===0.1){
					result.push([',',',']);
				}
				result.push([res, potenz]);
			}
			for (i=result.length-1; i>2; i--) {
				if (result[i][0] === 10) {
					if (result[i-1][0] === ','){
						result[i-2][0]++;
					} else {
						result[i-1][0]++;
					}
					result[i][0] = 0;
				}
			}
		})
		result = result.map((X,index) => {
			if (X[0] === ','){
				return <div className="komma" key="komma">,</div>
			} else {
				return( 
				<div className="stelle" key={"stelle"+index}>
				<button key={index} onClick={()=>this.change( X[1],1)}>▲</button>
				<span>{X[0]}</span> 
				<button key={'D'+index}  onClick={()=>this.change(X[1],-1)}>▼</button>
				</div>
				)
			}
		})
		return (
			<div className="feld-container DigitField">

				<div className="value">
				{result}
				</div>

			</div>
		);
	}
}

