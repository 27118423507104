let config = {
    api: {
        baseURL: 'https://tools.huebschi.de/api/'
    },
    maps:{
        apikey:'AIzaSyAryxvr18LV39z5r6HSAP079FMqlUBW73s'
    },
    FiBu:{
        Zinskonto:24,
        BargeldKonto:2
    },
    formatCurrency:(val:number) => (new Intl.NumberFormat('de-DE', { style: 'currency',currency: 'EUR'})).format(val),
    DatePicker:{
        strings:{
            months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
            days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnertag', 'Freitag', 'Samstag'],
            shortDays: ['S', 'M', 'D', 'M', 'D', 'F', 'S'],
            goToToday: 'Heute',
            prevMonthAriaLabel: 'Vormonat',
            nextMonthAriaLabel: 'Folgemonat',
            prevYearAriaLabel: 'Vorjahr',
            nextYearAriaLabel: 'Folgejahr'
        },
        placeholder:"Datum wählen",
        firstDayOfWeek:1,
        formatDate:(date?:Date|undefined) => {
            return date?.toLocaleDateString('de-DE',{ year: 'numeric', month: '2-digit', day: '2-digit' }) || ''
        },
        formatMysql:(e:Date|undefined|null)=>{
            
                var year, month, day;
                if (e===null || e===undefined){
                    return e;
                }
                year = String(e?.getFullYear());
                month = String(e?.getMonth() + 1);
                if (month.length === 1) {
                    month = "0" + month;
                }
                day = String(e?.getDate());
                if (day.length === 1) {
                    day = "0" + day;
                }
                return year + "-" + month + "-" + day;
        }
    }
}
export default config;