import React from "react";
import { TextField, DatePicker, TagPicker, TagItem, Text, IDropdownOption,Position, Pivot, PivotItem, IChoiceGroupOption, ChoiceGroup, Spinner, Dropdown, Toggle, DefaultButton } from "@fluentui/react";
import { IKontoDropdownOption, KontoDropdown } from "./konten";
import { SpinEdit, API, ListView, Libery, MakeFoto } from "../../common";
import config from '../../config';

interface BarerfassungProps{
  onBuchen?:(item:IBarerfassung)=>void
}
export interface IBarerfassung {
  betrag:number,
  media:string,
  text:string,
  datum?:Date,
  index?:number
}
export interface BarerfassungState extends IBarerfassung {
  items:IBarerfassung[]
}
export class Barerfassung extends React.Component<BarerfassungProps,BarerfassungState> {
  constructor(props:any){
    super(props);
    this.state = {
      betrag:0,
      media:'',
      text:'',
      items:[]
    }
  }
  componentDidMount(): void {
    
    API.get('/fibu/bargeld').then((result:any)=>  this.setState({
      betrag:0,
      media:'',
      text:'',
      items:result?.data?.reverse()
    }))
  }
    render (){
      return <div className="flex-column flex-grow">
        <div>
          <SpinEdit 
            label="Betrag"
            labelPosition={0}
            precision={2}
            step={0.01}
            onChangeCustom={(betrag:number) => {
              this.setState({betrag:betrag})
            }}
            value={String(this.state.betrag)}
          />
          <TextField
            label="Notiz"
            value={this.state.text}
            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined)=>this.setState({text:newValue||''})}
          />
          <DefaultButton
            text={config.formatCurrency(this.state.betrag)+' für "'+this.state.text+'" erfassen'}
            onClick={()=>{
              console.debug(this.state);
              API.post('/fibu/bargeld',{
                betrag:this.state.betrag,
                text:this.state.text
              }).then((result:any)=>  this.setState({
                betrag:0,
                media:'',
                text:'',
                items:result?.data
              }))
            }}
          />
 
          </div>
          <ListView
            items={this.state.items}
            className="striped"
            styles={{root:{maxWidth:'1000px'}}}
            layoutMode={1}
            columns={[
              {
                name:'Datum',
                onRender:(item:any)=><span >{config.DatePicker.formatDate(new Date(item.datum))}</span>,
                minWidth:70,
                maxWidth:90,
                className:'sticky'
              },{
                name:'Betrag',
                minWidth:50,
                maxWidth:50,
                onRender:((item:any)=>{
                return  (<span key={"preis"+item.id} style={{display:'block',textAlign:'right',maxWidth:"104px"}}>
                {Libery.formatCurrency(item?.betrag) }</span>)
                    })
              },
              {
                name:'Text',
                fieldName:'text'
              }
            ]}
            onActiveItemChanged={(item?: any, index?: number, ev?: React.FocusEvent<HTMLElement>) => {
              item.index = index;
              if (typeof(this.props.onBuchen) === 'function'){
                this.props.onBuchen!(item)
              }
            }}
            />
          {/*}
          <MakeFoto
            hidden={!this.state.showCamara}
            onTakePhoto={(dataURI:string) => this.setState({media:dataURI, showCamara:false})}
          />
          {this.state.media !== '' && <img alt="" src={this.state.media}/>}
          {/** */}
      </div>
    }
}
