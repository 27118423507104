import React from "react";
import { useParams } from "react-router";
import { SpinButton, ISpinButtonProps } from "@fluentui/react";
export const Libery = {
    formatNumber(n:number, digit:number=2,kom=',',tsd=''):string {
        var vz='';
        if (n<0) {
            vz='-';
            n*=-1;
            return '-'+this.formatNumber(n,digit,kom,tsd);
        }
        var G=Math.floor(n);
        if(isNaN(G)) {
            G=0;
        }
        var k=Math.floor((n-G)*Math.pow(10,digit)); //Kommstatelle
        var rt=vz;
        if (tsd!=='') {
            let i=0;
            while (Math.floor(G/Math.pow(1000,i))>0) {
                i++;	
            }
            let Z=G;
            while (i>0) {
                var a=Math.floor(Z/Math.pow(1000,i-1));
                if (rt!=='') {
                    if (a<10) {
                        rt+='00';
                    } else if (a<100){
                        rt+='0';
                    }
                }
                rt+=a+(i>1?tsd:'');
                Z=Z-Math.floor(Z/Math.pow(1000,i-1))*Math.pow(1000,i-1);
                i--;
            }
        }
        if (rt==='' || rt==='-'){
            var GN='';
            if (tsd !== '' && G > 1000){
              var end;
              var Glenth = String(G).length;
              var first = String(G).length % 3;
              GN=String(G).substr(0, first);
              for( let i = first, end = Glenth; i < end; i+=3) {
                GN+=String(G).substr(i,3);
              }
            } else {
                GN = String(G);
            }
            rt+=GN;
        }
        if (n-G>0) { 
            k=n-G;
            rt+= kom+k.toFixed(digit).substr(2);
        } else {
            if (digit>0 ) {
                rt+=kom;
            }
            for (let i=0; i<digit; i++) {
                rt+='0';
            }
        }
        return rt;
    },
    getWeek(date:Date) {
         date.setHours(0, 0, 0, 0);
        // Thursday in current week decides the year.
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        // January 4 is always in week 1.
        var week1 = new Date(date.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                              - 3 + (week1.getDay() + 6) % 7) / 7);
    },    
    formatDate(time:number, type?:string){
      if (typeof(type) === 'undefined'){
        type = 'JJJJMMDD';
      }
      var date = new Date(time*1000);
      var year = date.getFullYear();
      var Syear = (year%100)+'';
      var month = date.getMonth()+1;
      var day = date.getDate();
      var shortDay=date.toLocaleDateString('de-DE',{weekday:'short'})
      var week = this.getWeek(date);
      if (type === 'WK-JJJJ') {
        return  (week<10?'0':'')+week+'-'+year;
      } else {
        let result=type;
        
        result = result.replace('JJJJ',''+year);
        result = result.replace('JJ',Syear);
        result = result.replace('MM',(month<10?0:'')+''+month);
        result = result.replace('DD',(day<10?0:'')+''+day);
        result = result.replace('M',''+month);
        result = result.replace('D',''+day);
        result = result.replace('TT',shortDay);
        return result;
      }
  
    },
    formatDatum(time:number, format?:string){
      if (typeof(format)==='undefined'){
        format='TT.,DD.MM.JJJJ';
      }
        return this.formatDate(time,format);
    },
    formatCurrency:(val:number) => (new Intl.NumberFormat('de-DE', { style: 'currency',currency: 'EUR'})).format(val),
}
export const withRouter = (WrappedComponent:any) => (props:any) => {
    const params = useParams();
    // etc... other react-router-dom v6 hooks
  
    return (
      <WrappedComponent
        {...props}
        params={params}
        // etc...
      />
    );
  };
interface SpinEditProps extends ISpinButtonProps {
  type?:string,
  onChangeCustom:(newValue:number)=>void

}
export class SpinEdit extends React.Component<SpinEditProps> {

  render(){
    return <SpinButton
    {...this.props}
    onIncrement={(value)=>{
      return this.setSpinEdit((this.props.step||0));
    }}
    onDecrement={(value)=>{
      return this.setSpinEdit(-(this.props.step||0));
    }}
    onChange={(e,newValue?:string) => {
      this.props.onChangeCustom!(Number(newValue?.replace(',','.'))) 
    }}        
    inputProps={{type:'number', className:'spinButton'}}
    />
  }
  setSpinEdit(change:number){
   let value:number = parseFloat(this.props.value||'0')+change;
    value = Math.round(value*100000)/100000;
    if (typeof(this.props.max) !== 'undefined' && this.props.max < value)  {
      value = this.props.max;
    }
    if (typeof(this.props.min) !== 'undefined' && this.props.min > value)  {
      value = this.props.min;
    }
    this.props.onChangeCustom(value)
  }
}

export async function copyTextToClipboard(text:string) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}