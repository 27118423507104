import React from 'react';
import { useNavigate  } from 'react-router-dom';
import { Nav } from '@fluentui/react';

import './NavWithRouter.css';
class NavWithRouter extends React.Component {
	render() {
		
	//	let navigate = useNavigate();
	/*
		const iconClass = mergeStyles({
			height: 54,
			width: 54,
			margin: '5px 15px 5px 0px',
			fill: theme.palette.white,
			stroke: theme.palette.themePrimary,
			color: theme.palette.white,
			background:'none',
			display:'flex !important',
			padding:'4px',
			alignItems:'center',
			justifyContent:'center'

		});	
		const styles  = {
			link: {
				background:theme.palette.themePrimary,
				height:70,
				marginBotton:'1em',
				display:'block',
				selectors: {
					':hover':{
						background: theme.palette.themeDark +' !important',
						color: theme.palette.white
					},
					':focus':{
						background: theme.palette.themeDark +' !important',
						color: theme.palette.white
					}
				}
			},
			labelHovered:{
					color:'red'
			},
			navItem: { 
				padding:'10px 0'
			}
		}
		const styles2  = {
			root: {
				fontSize:FontSizes.xxLarge,
				color:theme.palette.white,
				padding: '5px 0',
				background: 'none'
			},
			labelHovered: {
				fontSize:FontSizes.xxLarge,
				color:theme.palette.white,
				padding: '5px 0',
				background: 'none'
			}
		}
		/** */
		let SelItems = [];
		if(typeof this.props.groups !== 'undefined' && typeof this.props.groups[0] !== 'undefined'){
			SelItems = this.props.groups[0].links
			.filter(element => window.location.pathname.includes(element.link) && element.link!=='')
			.sort((a,b)=>{
				if (a.link.length < b.link.length){
					return 1
				}
				if (a.link.length > b.link.length){
					return -1
				}
				return 0
			}); 
		}


		return (
			<div className={"navigation " + this.props.className}>
				
				<Nav
					{...this.props}
					onLinkClick={(event, element) => {
						
					//	event.preventDefault();
					//	this.push(element.url)
						this.props.afterClick();
					}}
					
					selectedKey={SelItems.length > 0 ? SelItems[0].key : ''}
				/>
			</div>
		)
	}
	push(url){

		let navigate = useNavigate();
		navigate(url,{replace:true});
	}
}

export default (NavWithRouter);