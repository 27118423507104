import React from "react";
import { IconButton } from "@fluentui/react";

export class DBNavigator extends React.Component<{
    index:number,
    length:number,
    onSetIndex:(index:number) => void
}>  {
    render(): React.ReactNode {
        return <>
        
<IconButton 
    iconProps={{iconName:'DoubleChevronLeftMed'}} 
    onClick={()=>this.props.onSetIndex(0)}
    disabled={this.props.index===0}
    />
    <IconButton 
    iconProps={{iconName:'ChevronLeftMed'}} 
    onClick={()=>this.props.onSetIndex(Math.max(0,this.props.index -1)) }
    disabled={this.props.index===0}
    />
    <IconButton 
    iconProps={{iconName:'ChevronRightMed'}} 
    onClick={()=>this.props.onSetIndex(Math.min(this.props.length-1,this.props.index +1))}
    disabled={this.props.index===this.props.length-1}
    />
    <IconButton 
    iconProps={{iconName:'DoubleChevronLeftMedMirrored'}} 
    onClick={()=>this.props.onSetIndex(this.props.length-1)}
    disabled={this.props.index===this.props.length-1}
    />
        </>
    }
}