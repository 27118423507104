import React, { useState } from 'react';


interface DieProps {
  sides: number;
}

export const Wuerfel: React.FC<DieProps> = ({ sides }) => {
  const [results, setResults] = useState<number[]>([]);
  const [currentResult, setCurrentResult] = useState<number | null>(null);

  const rollDie = () => {
    const randomNumber = Math.floor(Math.random() * sides) + 1;
    setCurrentResult(randomNumber);
    setResults([...results, randomNumber]);
  };

  return (
    <div style={{padding: '0 1em', maxWidth: '200px', textAlign:'center'}}>
      <h2>Würfel</h2>
      <button onClick={rollDie} style={{width:'50px', height:'50px', border:'1px sold #222',borderRadius:'20%', fontSize:'2em'}}>{currentResult}</button>
    
      {results.length > 0 && (
        <div>
          <p>Wuerfe:</p>
          <ul>
            {results.map((result, index) => (
              <li key={index}>{result}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};