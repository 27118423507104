import React from 'react';
import { IColumn } from '@fluentui/react';

import { API, ListView, DitgitField, Libery } from '../common';

const WeightConfig = {
  apiBase:  '/weight',
}
interface WColumn extends IColumn{
  type?:string,
  unit?:string,
  digits?:number
}

class Weight extends React.Component<
  {},
  {
    items:any[],
    last:number
  }
> {
  constructor(props:any){
    super(props);

    this.state = {
      items: [],
      last: 0
    }
  }

  componentDidMount(){
    this._getItems();
  } 


  render() {
    return (
      <div className="Bike component">
        <div className="main-view">
        <header>
              
              <DitgitField value={this.state.last} 
                    onChange={this.handleChangeWeight.bind(this)}/>
        
             <button onClick={this.handleBuchen.bind(this)}>Buchen</button>
             <button onClick={this.handleDeleteLast.bind(this)}>letzte Buchung Löschen</button>
       </header>
       <ListView
						items={this.state.items||[]}					
          	columns={ [
              
              {
                key: 'column1',
                name: 'Datum',
                fieldName:'time',
                isResizable: true,
                isPadded: false,
                minWidth:65,
                maxWidth: 65,
                type:'date'
              } ,
              {
                key: 'column2',
                name: ('Abnahme'),
                fieldName:'differenz',
                isResizable: true,
                isPadded: false,
                className:'umbruch',
                minWidth:45,
                maxWidth: 60,
                type:'number',
                digits:2,
                unit:'kg'
              } ,
              {
                key: 'column3',
                name: 'Gesamt',
                fieldName:'weight',
                isResizable: true,
                isPadded: false,
                minWidth:60,
                maxWidth: 60, 
                type:'number',
                digits:2,
                unit:'kg'
              } ,
            ]}
                
            onRenderItemColumn={(item:any, index?:number, column?:WColumn)=>{
              if (index){}
              let columnType =typeof(column?.type)!=='undefined' ? (column?.type) : '';
              let columnUnit =typeof(column?.unit)!=='undefined' ? ' '+(column?.unit) : '';
              let inhalt='';
              switch (columnType){
                case 'number':
                  inhalt = Libery.formatNumber(item[column?.fieldName||''],column?.digits,',','.')+columnUnit;
                break;
                case 'date':
                  inhalt = Libery.formatDatum(item[column?.fieldName||'']);
                break;
                default: 
                  inhalt = item[column?.fieldName||''];
                break;
                
              }
              return (<span style={{
                          display: 'block',
                          textAlign: 'right',
                      }}>{inhalt}</span>)
            }}
          />
        </div>
      </div>
    );
    
  }
  handleChangeWeight(pvalue:number){
    this.setState({
      last : pvalue
    });
  }

  handleBuchen(){
    API.post(WeightConfig.apiBase, {weight:this.state.last})
      .then( (result) => {
        this._getItems();
      });
  }

  handleDeleteLast(){
    if (window.confirm('Letzte Buchung löschen')){
      API.delete(WeightConfig.apiBase )
        .then( () => {
          this._getItems();
        });
    }
  }
  //API-Aufruf, um die die Kunden abzurufen
  _getItems(){ 
    var last;
    API.get(WeightConfig.apiBase )
      .then( (result) => {
        result = result.reverse();
        
        if (result.length > 2 ) {
          last = result[0].weight;
        } else {
          last = 0;
        }
        this.setState({
          items: result,
          last : last
        }); 
    }).catch( (error) => {
      console.error(error);
    });
  }

  
}

export default Weight;