import React from 'react';

import { API,Libery,  FormatZeit, FormatTime, ListView } from '../common';

import { DetailsList, SelectionMode,DetailsListLayoutMode, ScrollablePane, ScrollbarVisibility,  Sticky, StickyPositionType, MarqueeSelection, Icon, getTheme, mergeStyleSets, IGroup, IColumn } from '@fluentui/react';
const theme = getTheme();
const GROUP_HEADER_AND_FOOTER_SPACING = 8;
const GROUP_HEADER_AND_FOOTER_BORDER_WIDTH = 1;


const TimeConfig = {
  apiBase:  '/time',
}
class Time extends React.Component<{}, {
  times:any[]
}> {
  timer?:NodeJS.Timeout;
  constructor(props:any){
    super(props);

    this.state = {
      times: [],
    }
  }

  componentDidMount(){
    this._getTimes();
    
    this.timer = setTimeout(()=>this.tick(), 60000);
  } 

  render() {
    
    const classNames = mergeStyleSets({
      headerAndFooter: {
        borderTop: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
        borderBottom: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
        padding: GROUP_HEADER_AND_FOOTER_SPACING,
        margin: `0`,
        background: theme.palette.neutralLighterAlt,
        // Overlay the sizer bars
        position: 'relative',
        display:'flex',
        alignItems:'center',
        zIndex: 100
      },
      headerTitle: [
        theme.fonts.xLarge,
        {
          padding: '4px 0'
        }
      ],
      headerLinkSet: {
        margin: '4px -8px'
      },
      headerLink: {
        margin: '0 8px'
      }
    });
    var times = this.state.times;
    
  let groups:IGroup[] = [];
  let last = '';
  for (let i=0; i<times.length; i++){
    let key=Libery.formatDate(times[i].kommen.timestamp,'JJJJ/MM');
    if (key !== last) {
      let heute = Libery.formatDate(Math.round((new Date()).getTime()/1000),'JJJJ/MM');
      groups.push({ 
        startIndex:0,
        key: key,
        name: key,
        count: 1,
        isCollapsed:key!==heute
       })
    } else {
      groups[groups.length-1].count++;
    }
    last=key;
}
let groups2:IGroup[]= [];
last=''
let startIndex=0;;
if (groups.length > 0){
  for (let i=0; i<groups.length; i++){
    let key=(groups[i].key.split('/')[0]);
    groups[i].startIndex=startIndex;
    groups[i].level=2;
    if (groups2.length > 0) {
      if (key === groups2[groups2.length-1].key){
        groups2[groups2.length-1].count+=groups[i].count;
        groups2[groups2.length-1].children?.push(groups[i]);
      } else {
        groups2.push({
            key: key,
            name: key,
            startIndex:  startIndex,  
            count: groups[i].count,
            isCollapsed:key!==Libery.formatDate(Math.round((new Date()).getTime()/1000),'JJJJ'),
            level:1,
            children:[groups[i]]
        })
      }
    } else {
      groups2.push({
          key: key,
          name: key,
          startIndex:  startIndex, 
          count: groups[i].count,
          isCollapsed:key!==Libery.formatDate(Math.round((new Date()).getTime()/1000),'JJJJ')+'',
          level:1,
          children:[groups[i]]
      })
    }
    startIndex+=groups[i].count;
  }
}
    var text = "kommen";
    if (times.length > 0){
      if (times[0].gehen.datetime==="NOW"){
        text="gehen";
      }
    }
    return (
      <div className="Time component">
        <div className="main-view">
          <div className="toolbar">
          <button onClick={this.handleComeAndGo.bind(this)}>{text}</button>
          <button onClick={this.handleDeleteLast.bind(this)}>letzte Buchung Löschen</button>
          <button onClick={this.tick.bind(this)}>Live Time laden</button>
          </div>
					<ListView
						items={times}
						compact={true}
            groups={groups2}
                
            groupProps={{
              onRenderHeader:  (props:any) => {
                
                return (
                  <div className={classNames.headerAndFooter} onClick={
                    ()=> {
                      return  props.onToggleCollapse(props.group);
                    }
                  }>
                  
                  <Icon iconName={props.group.isCollapsed?'ChevronRightMed':'ChevronDownMed'} className="ms-IconExample" />
                  
                    <div style={{width:'5em',paddingLeft:'0.8em'}}className={classNames.headerTitle}>{props.group.name}</div>
                    {true &&
                    <div style={{display:'flex'}}>
                     <div style={{width:'40em',textAlign:'right'}}>{FormatTime({dauer: times[props.group.startIndex]?.sum})} </div>
                     <div style={{width:'5.9em',textAlign:'right'}}> </div>
                     <div style={{width:'5em',textAlign:'right'}}>{FormatTime({dauer: times[props.group.startIndex]?.sumAV})} </div>
                    </div>}
                  </div>
                );
              }
            }}
						columns={ [
              
              {
                key: 'column1',
                name: 'Datum',
                isResizable: true,
                isPadded: false,
                minWidth:100,
                maxWidth: 100,
                fieldName:'datum'
              } ,
              {
                key: 'column2',
                name: 'Kommen',
                isResizable: true,
                isPadded: false,
                minWidth:50,
                maxWidth: 50,
                onRender:(line:any)=> {
                  return  (<FormatZeit dauer={line.kommen.timestamp} /> )
                }
              } ,
              {
                key: 'column3',
                name: 'Gehen',
                isResizable: true,
                isPadded: false,
                minWidth:40,
                maxWidth: 40,
                onRender:(line:any)=> {
                  return  (<FormatZeit dauer={line.gehen.timestamp} item={line} /> )
                }
              }  ,
              {
                key: 'column4',
                name: 'Ist',
                fieldName:'dauer',
                type:'time',
                isResizadable: true,
                isPadded: false,
                minWidth:40,
                maxWidth: 40
              }  ,
              {
                key: 'column5',
                name: 'Soll',
                fieldName:'soll',
                type:'time',
                isResizadable: true,
                isPadded: false,
                minWidth:40,
                maxWidth: 40
              } ,
              {
                key: 'column6',
                name: '+/-',
                fieldName:'diff',
                type:'time',
                isResizadable: true,
                isPadded: false,
                minWidth:40,
                maxWidth: 40
              } ,
              {
                key: 'column7',
                name: 'RealSum',
                fieldName:'sum',
                type:'time',
                isResizadable: true,
                isPadded: false,
                minWidth:50,
                maxWidth: 50
              } ,
              {
                key: 'column8',
                name: 'SummM',
                fieldName:'sumM',
                type:'time',
                isResizadable: true,
                isPadded: false,
                minWidth:50,
                maxWidth: 50
              } ,
              {
                key: 'column9',
                name: 'AV',
                fieldName:'sumAV',
                type:'time',
                isResizadable: true,
                isPadded: false,
                minWidth:40,
                maxWidth: 40
              } ,
              {
                key: 'column10',
                name: 'Freigrund',
                isResizadable: true,
                isPadded: false,
                minWidth:55,
                maxWidth: 55,
                fieldName:'FreiGrund'
              } 
            ]}
            
						selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            
            onRenderItemColumn={(item:any, index:number, column:any)=>{
              let columnType =typeof(column.type)!=='undefined' ? (column.type) : '';
              let columnUnit =typeof(column.unit)!=='undefined' ? ' '+(column.unit) : '';
              switch (columnType){
                case 'number':
                  return Libery.formatNumber(item[column.fieldName],column.digits,',','.')+columnUnit;
                case 'date':
                  return Libery.formatDatum(item[column.fieldName]);
                case 'time':
                  return FormatTime({dauer:item[column.fieldName]})
                default: 
                  return item[column.fieldName];
                
              }
            }}
            onRenderDetailsHeader={(props:any, defaultRender:any)=> {
              return (
                <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                  {!defaultRender(props)}
                </Sticky>
              );
            }}
					/>
         </div>
      </div>
    );
    
  }

  tick(){
      var times = this.state.times;
      if (times.length > 0){
        if (times[0].gehen.datetime==="NOW"){
          var old = times[0].gehen.timestamp;
          times[0].gehen.timestamp=(Math.round((new Date()).getTime()/1000/60-0.5)*60);
          var diff = times[0].gehen.timestamp - old;
          times[0].dauer+=diff;
          times[0].diff+=diff;
          times[0].sum+=diff;
          times[0].sumM+=diff;
          times[0].sumG+=diff;
          times[0].sumAV = times[1].sumAV+times[0].diff;

          this.setState({
            times: times
          }); 
        }
      }
      API.getAuth();
      var milli = (new Date()).getTime();
      var rest = milli % 60000; 
      this.timer = setTimeout(this.tick.bind(this), 60000-rest);
  }

  handleComeAndGo(){
    API.post(TimeConfig.apiBase,{})
      .then( (result) => {
        this._getTimes();
      });
  }

  handleDeleteLast(){
    if (window.confirm('Letzte Buchung löschen')){
      API.delete(TimeConfig.apiBase )
        .then( (result) => {
          this._getTimes();
        });
    }
  }

  //API-Aufruf, um die die Kunden abzurufen
  _getTimes(){ 
    API.get(TimeConfig.apiBase )
      .then( (result) => {
        result.forEach((element:any, index:number) => {
          element.datum=Libery.formatDatum(element.kommen.timestamp) ;
          if (element.gehen.timestamp === 0 ){
            var now = new Date();
            element.gehen.timestamp=(Math.round(now.getTime()/1000/60-0.5)*60);
            element.gehen.datetime="NOW";
            
          }
          if (index > 0) {
            if (element.datum === result[index-1].datum){
              element.datum ='';
            }
          }
        }); 

        result = result.reverse();
        this.setState({
          times: result
        }); 
    }).catch( (error) => {
      console.error(error);
    });
  }

  refreshTimes(){
    this._getTimes();
  }
  
}

export default Time;