import React, {Component} from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import { getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';

const theme = getTheme();
 

export class Accordion extends Component {
	constructor(props) {
		super(props);

        this.state = {
                open:[]
        }
        
		
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.open) !== JSON.stringify(this.props.open)) {
            this.setState({open:this.props.open})
        }
        return null;
    }
    componentDidMount(prevProps) {
            this.setState({open:this.props.open || []})
    }
    headerClick(index){
      console.debug(this.props?.children?.[index])
      console.debug(this.props?.headerClick)
        if (typeof(this.props.headerClick)==='function'){
            this.props.headerClick(index,this.props.children[index]);
        } else {
            let open = [...this.state.open];
            if(open.indexOf(index) >=0){
              open.splice(open.indexOf(index),1);
            } else {
              open.push(index);
            }
            this.setState({open:open})
        }
    }
    
	render() {
        let children = [],i=0;
        for (i=0; i<this.props.children.length;i++){
          if (typeof(this.props.children[i])==='object'){
            console.debug(this.props.children[i]?.props.isCollapsed)
           let child= React.cloneElement(this.props.children[i],{'headerClick':(e)=>{
                    this.headerClick(e)
                },index:i, 
                
                isCollapsed: this.state.open?.indexOf(this.props.children[i].props?.itemKey)>=0 || this.props.children[i]?.props?.isCollapsed });
            
           children.push(child);
          }
        }
        
        let props = {...this.props};
        delete props.headerClick; 
        delete props.isCollapsed;
        delete props.headerTitle;
        delete props.children;
        delete props.itemKey;
        return <div className={this.props.className||''}>{children}</div>
	}
}

export class AccordionItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: '',
			gaddress :'',
			load:0,
			newBundeslandData:{}
		}
		
		
	}
	render() {
       const baseStyle= {
          headerAndFooter: {
            borderTop: `1px solid ${theme.palette.neutralQuaternary}`,
            borderBottom: `1px solid ${theme.palette.neutralQuaternary}`,
            padding: 5,
            margin: `0`,
            background: theme.palette.neutralLighterAlt,
            // Overlay the sizer bars
            position: 'relative',
            display:'flex',
            alignItems:'center',
            zIndex: 100
          },
          headerTitle: [
            theme.fonts.xLarge,
            {
              padding: '4px 0',
              flexGrow:1
            }
          ],
          headerLinkSet: {
            margin: '4px -8px'
          },
          headerLink: {
            margin: '0 8px'
          }
        }
        const classNames = mergeStyleSets(Object.assign(baseStyle,this.props.styles||{}));

        return <div className={ this.props.isCollapsed?' flex-grow flex-column ':''}>
                    <div className={classNames.headerAndFooter} onClick={this.props.headerClick.bind(this, this.props.itemKey)}>
                        
                        <Icon iconName={!this.props.isCollapsed?'ChevronRightMed':'ChevronDownMed'} className="ms-IconExample" />
                        
                        <div style={{paddingLeft:'0.8em'}} className={classNames.headerTitle}>
                        {this.props.headerText}
                        </div>
                    </div>
                    {this.props.isCollapsed&&
                    <div className="content flex-grow flex-column" style={{minHeighjt:'80vh',padding:'1em 0',...this.props.contentStyle}}>{this.props.children}</div>
        	        }
                </div>
	}
}
