import React from 'react';
import {  Routes, Route} from 'react-router-dom';
import './App.css';
import config from './config';  
import { API } from './common';
import Dashboard from './views/Dashboard';
import Time from './views/time';
import Holiday from './views/holiday';
import Bike from './views/bike';
import Weight from './views/weight';
import Passwords from './views/passwords';
import Fibu from './views/Fibu';
import Boerse from './views/Boerse';
import Login from './views/user/Login';
import UserAddOn from './views/user/AddOn';
import NavWithRouter from './common/NavWithRouter'

import { initializeIcons, registerIcons, loadTheme,getTheme,createTheme } from '@fluentui/react';
import { Wuerfel } from './views/gadgets';
import { useLocation,  useParams } from 'react-router-dom';

export function RouteComponent(props:any) {
    let location = useLocation();
    let params = useParams();
    let WrappedComponent = props.component;
    return (
      <WrappedComponent
        {...props}
        router={{ location, params }}
      />
    );
}


loadTheme(createTheme(
  { palette: {
    themePrimary: '#6ab675',
    themeLighterAlt: '#f8fcf8',
    themeLighter: '#e3f3e5',
    themeLight: '#cbe9d0',
    themeTertiary: '#9ed3a6',
    themeSecondary: '#78be82',
    themeDarkAlt: '#5fa369',
    themeDark: '#508a58',
    themeDarker: '#3b6541',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    neutralLight: '#eaeaea',
    neutralQuaternaryAlt: '#dadada',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c8c8',
    neutralTertiary: '#c2c2c2',
    neutralSecondary: '#858585',
    neutralPrimaryAlt: '#4b4b4b',
    neutralPrimary: '#333333',
    neutralDark: '#272727',
    black: '#1d1d1d',
    white: '#ffffff',
  }

  }))
class App extends React.Component {
  state = {
    intervall : false,
    loggedIn : false,
    user:{id:0,username:''},
    count:0
  };
  icons={};
  componentDidMount(){
    
    initializeIcons();
    if (typeof(localStorage.API) !== 'undefined') {
      let api = JSON.parse(localStorage.API);
      
      if((new Date()).getTime()/1000 > api?.refresh?.expires) {
       return this.doLogout()
      } 
    } else {
      return this.doLogout()
    }
    if (localStorage.user){
      const user = JSON.parse(localStorage.user);
      if (user.loggedIn){
        
        this.setState({
          'loggedIn' : true,
          user: user
        },()=>API.get('/users/me').then(result=>this.setState({user:result})));
      }
    }
    let theme= getTheme()
		fetch('/config.json').then(response => response.json())
    .then(resp=>{
      this.setState({themeLoaded:true, config:resp});
      for (let key in resp.icons){

        fetch('svg/'+resp.icons[key]).then(response => response.text())
        .then(resp=>{
         // this.icons['svg-'+key]=(React.createElement('span',{dangerouslySetInnerHTML:{__html: resp},style:{width:'16px',fill:theme.palette.themePrimary,display:'inline-block'}}))
          registerIcons({icons:this.icons});
          this.setState({icons:this.icons})
        });
      }
      
    }); 
    
  }
  doLogin(username:string, passwort:string){
    console.clear();
    let config:any={}
    const data =  {
      username: username, 
      passwort:passwort
    };
    fetch(API.base+'/users/login', {
      body: JSON.stringify(data),
      cache: 'no-cache',
      headers: {
          'Content-Type': 'application/json',
      },
      method: 'POST',
      mode: 'cors'
  }).then(response => response.json()).then( (result) => {
    
      localStorage.setItem('API', JSON.stringify(result.token));
      localStorage.setItem('user', JSON.stringify({
        loggedIn : result.success,
        data: result.data.id
      }));
      if (result.success){
        this.setState({
          user:result.data,
          loggedIn:true
        });
      }
      /** */
    }).catch( (error) => {
    console.error(error);
    });
  }
  doLogout(){
    if (this.state.loggedIn === true ) {
      this.setState({
        user:null,
        loggedIn:false
      });
      localStorage.removeItem('API');
      localStorage.removeItem('user');
      location.reload();
    }
    return false;
  }
  render() {
       
    
    let theme = getTheme();
    return this.state.loggedIn === true ? (
     
        <div className="App">
        <LoginsTicker onLogout={()=>this.doLogout()} />
        <div className='navigation'>
        <NavWithRouter 
                className={'PortalNav '} 
                groups={[{
                  links:
                  [
                 {
                   name: 'Tilos Tools',
                   icon:'GlobalNavButton',

                   links: [
                      {
                        name: 'Dashbord',
                        url: '/',
                        key: 'key0',
                        icon:'Home'
                      },
                      {
                        name: 'Zeiterfassung',
                        url: '/time',
                        key: 'key3',
                        isExpanded: true,
                        icon:'Clock',
                        links:[
                          {
                            name: 'Urlaub',
                            url: '/time/holiday',
                            icon:'AirplaneSolid',
                          },
                        ]
                      },
                      {
                        name: 'Fahrrad',
                        url: '/bike',
                        key: 'key4',
                        icon:'Cycling'
                      },
                      {
                        name: 'Gewicht',
                        url: '/weight',
                        key: 'key5',
                        icon: 'Compare'
                      },
                      {
                        name: 'Passwörter',
                        url: '/passwords',
                        key: 'key6',
                        icon: 'Permissions'
                      },
                      {
                        name: 'FiBu',
                        url: '/fibu',
                        icon: 'Money',
                        key: 'key7',
                        /*isExpanded: true,
                        links:[
                          {
                            name: 'Börse',
                            url: '/fibu/boerse',
                            icon: 'Diagnostic',
                            key:1
                          },
                        ] /** */
                      },{
                        
                        name: 'Würfel',
                        url: '/tools/wuerfel',
                        key: 'key8',
                        icon: 'CubeShape'
                      },{
                        
                        name: <>angemeldet als {this.state.user?.username} - jetzt abmelden</>,
                        url: '/tools/wuerfel',
                        key: 'key9',
                        Boerseicon: 'FollowUser',
                        onClick:()=>this.doLogout()
                      }
                ]}]
              }]
              }
                afterClick={()=>{
                  console.clear();
                }}
               />
               </div>
            <main>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/time" element={<Time />} />
                <Route path="/time/holiday" element={<Holiday mode={"normal"} />} />
                <Route path="/bike/:id?" element={<Bike mode={"normal"} />} />
                <Route path="/weight" element={<Weight />} />
                <Route path="/passwords" element={<Passwords />}  />
                <Route path="/fibu" element={<Fibu />} />
                  <Route path="/fibu/boerse" element={<Boerse />}  />
                <Route path="/tools/wuerfel" element={<Wuerfel sides={6} />} />
              </Routes>
            </main>
          </div>
    ) : (
      <Login onLogin={(username:string, password:string)=>this.doLogin(username,password)} />
    );
  }
}

export default App;

class LoginsTicker extends React.Component<{onLogout:()=>void},{count:number}>{
    state = {
      count:0
    }
    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any): void {
      setTimeout(()=>this.setState({count:this.state.count+1}, ()=>{
        API.getAuth();
      }),500)
    }
    componentDidMount(): void {
      this.setState({count:this.state.count++})
    }
    render(): React.ReactNode {
      return <></>
    }

}