import React from "react";
import { DefaultButton,TextField, Dropdown,Panel,PanelType, Sticky, StickyPositionType,Selection, SelectionMode, CommandBar, Toggle, Position, PrimaryButton, IDropdownOption, IDropdownProps, mergeStyleSets, getTheme, Icon, Pivot, PivotItem,IDetailsHeaderProps,IRenderFunction, IGroup, Text, IconButton, Modal, FontWeights, IStyleSet } from "@fluentui/react";
import {ListView,SpinEdit,API, Libery, DBNavigator, Accordion, AccordionItem } from "../../common";
import Formular from "../../common/Formular";
import config from "../../config";
import { Kreditrechner, KreditrechnerProps } from "./rerchner";
import { IBuchung, blankBuchung } from "./buchung";
const theme = getTheme();


export interface IKontoDropdownOption extends IDropdownOption {
  ID:number
}
export  interface KontoDropdownProps extends IDropdownProps{
  onChangeCustom:(item:IDropdownOption|undefined)=>void
}
export class KontoDropdown extends React.Component<KontoDropdownProps> {
    render (){
        return <Dropdown {...this.props}
                options={this.props.options.map((I:any) => {
                    I.key = I.ID;
                    return I;
                })}
                onChange={(e:React.FormEvent<HTMLDivElement>, item:IDropdownOption<any> | undefined)=>this.props.onChangeCustom(item)}
                />
    }
}
interface  KontoListeProps {
  konten:any[],
  kontotypen:any[],
  onSave:(item:any)=>void
}
interface KontoListeState {
  kontoTyp:string | number,
  kontoStatus: number,
  selectedKto:any,
  initSelectedkonto:any,
  lastSelection:number,
  darlehenInfoIndex:number

}
export interface DarlehenInfo {
  Beginn?:Date | null | undefined,
  Ende?:Date | null | undefined,
  ab?:Date | null | undefined,
  Betrag?:number,
  Ratenzahl?:number,
  zum?:number,
  Rate?:number,
  Zins?:number
}
export class KontoListe extends React.Component<KontoListeProps, KontoListeState > {
  private kontoSelection:Selection;
  props:KontoListeProps;
  state:KontoListeState = {
    kontoTyp:0,
    kontoStatus:-1,
    selectedKto : null,
    initSelectedkonto: null,
    lastSelection:0,
    darlehenInfoIndex:0,
  }
  
    
  constructor(props:KontoListeProps){
    super(props);
    this.props = props;
    
    this.kontoSelection = new Selection({
      onSelectionChanged:()=>{
        let sel = this.kontoSelection.getSelection();
        if(sel.length > 0){
          this.setState({
            selectedKto:JSON.parse(JSON.stringify(sel[0])), 
            initSelectedkonto:sel[0], 
            lastSelection:(new Date).getTime(),
            darlehenInfoIndex:0
          })
        }
      }
    })
  }
    render(){
       let Items:any[]= this.props.konten.filter((i:any)=>{
          let T = i.Typ===this.state.kontoTyp || this.state.kontoTyp===0
          let S = i.Aktiv===this.state.kontoStatus || this.state.kontoStatus=== -1;
          return T && S
       })||[];

        return <>
        <Dropdown
        label="KontoTyp"
        options={[{key:0, text:"alle"},...this.props.kontotypen]}
        selectedKey={this.state.kontoTyp}
        onChange={(e:React.FormEvent<HTMLDivElement>, option?:IDropdownOption)=>this.setState({kontoTyp:option?.key||''})}
      />
      <Dropdown
      label="Kontostatus"
      options={[{key:-1, text:"alle"},{key:1, text:"aktiv"},{key:0, text:"inaktiv"}]}
      selectedKey={this.state.kontoStatus}
      onChange={(e:React.FormEvent<HTMLDivElement>, option?:IDropdownOption)=>this.setState({kontoStatus:Number(option?.key)})}
    />
        <CommandBar
            items={[
                {
                  key:"new",
                    name:"neues Konto",
                    iconProps: { iconName: 'Add' },
                    onClick:()=>this.setState({
                      selectedKto:{ID:0,Typ:0},
                      darlehenInfoIndex:0
                    })
                }
            ]}
        />
      <ListView
        items={Items}
        layoutMode={1}
        columns={[
          {
            name:"ID",
            minWidth:20,
            maxWidth:50
          },
          {
            name:"Bezeichnung"
          },
          {
            name:"Typ",
            fieldName:"TypText",
            isMultiline:true,
            minWidth:200,
            onRender:(item:any)=>this.props.kontotypen.filter((T:any)=>T.key === item.Typ).map((T:any)=>T.text)
          }
        ].map((I:any)=>{
          if (typeof(I.fieldName)==='undefined') {
            I.fieldName = I.name
          }
          return I;
        })}
        selection={this.kontoSelection}
        selectionMode={SelectionMode.single}
      />
      
      <Panel
            isOpen={this.state.selectedKto !== null}
            type={PanelType.medium}
            headerText={'Konto'}
            onDismiss={()=>this.closeKonto()}
            onRenderFooterContent={()=><>
            
            <PrimaryButton 
                    text={'Speichern'}
                    onClick={()=>{
                      this.props.onSave(this.state.selectedKto)
                      this.closeKonto();
                    }}
                    disabled={JSON.stringify(this.state.selectedKto) === JSON.stringify(this.state.initSelectedkonto)}
                /> <DefaultButton 
                    text={'abbrechen'}
                    onClick={()=>this.closeKonto()}
                />
            </>}
        >
          {this.state.selectedKto&&
            <Pivot>
              <PivotItem headerText="Konto">
                <TextField 
                    label="ID" 
                    disabled 
                    value={this.state.selectedKto?.ID}
                />
                <Dropdown 
              	  label="Typ"
                  options={this.props.kontotypen}
                  selectedKey={this.state.selectedKto?.Typ}
                  onChange={(e,option)=>this.changeSelectedKonto('Typ', option?.key)}
                />
                <TextField 
                    label="Bezeichung" 
                    value={this.state.selectedKto?.Bezeichnung} 
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string)=>this.changeSelectedKonto('Bezeichnung', value)}
                />
                <TextField 
                    label="Bezeichung kurz" 
                    value={this.state.selectedKto?.Bezeichnungkurz} 
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string)=>this.changeSelectedKonto('Bezeichnungkurz', value)}
                />       
                <SpinEdit 
                    label="Bestand" 
                    labelPosition={Position.top}
                    min={0}
                    step={0.01}

                    precision={2}
                    value={this.state.selectedKto?.Bestand} 
                    onChangeCustom={(value:number)=>this.changeSelectedKonto('Bestand', value)}
                />       
                <Toggle label="Status" checked={this.state.selectedKto?.Aktiv === 1} 
                    onText="Konto aktiv" offText="Konto inaktiv" 
                    onChange={(e: React.MouseEvent<HTMLElement, MouseEvent>, checked?:boolean) => this.changeSelectedKonto('Aktiv', checked ? 1 : 0)}
                />
              </PivotItem>
              {this.state.selectedKto?.Typ === 9 && <PivotItem key={'dl'} headerText="Darlehen">
                <div style={{display:"flex"}}>
                  <DefaultButton 
                    text='neues Darlehen' 
                    onClick={()=>{
                      let meta = {...this.state.selectedKto.meta}
                      if (typeof(meta.DL)==='undefined') {
                        meta.DL = []
                      }
                      let Datum = config.DatePicker.formatMysql(new Date);
                      meta.DL.push({Beginn:Datum, Ratenzahl:12, Zins:5, zum:1, ab:Datum, Ende:null,Betrag:10000 })
                      this.changeSelectedKonto('meta',meta,()=>this.setState({darlehenInfoIndex:meta.DL.length-1}));
                    }} 
                  />
                  <DBNavigator 
                    index={this.state.darlehenInfoIndex}
                    length={this.state.selectedKto?.meta?.DL?.length}
                    onSetIndex={(index:number)=>this.setState({darlehenInfoIndex:index})}
                  />
                </div>
                {this.state.darlehenInfoIndex<this.state.selectedKto?.meta?.DL?.length ?
                   <DarlehenForm
                    key={this.state.darlehenInfoIndex} 
                    darlehen={this.state.selectedKto?.meta?.DL?.[this.state.darlehenInfoIndex]} 
                    onChange={(darlehen:DarlehenInfo)=>{
                      let meta = {...this.state.selectedKto.meta}
                      meta.DL[this.state.darlehenInfoIndex] = darlehen;
                      this.changeSelectedKonto('meta',meta);
                    }}
                  /> 
                  : <React.Fragment key={this.state.darlehenInfoIndex} />
                }
                {(this.state.selectedKto?.meta?.DL||[])
                .sort((a:DarlehenInfo, b:DarlehenInfo)=>{
                  if ((new Date(a.Beginn||'')).valueOf() > (new Date(b.Beginn||'')).valueOf()  ) {
                    return 1
                  };
                  if ((new Date(a.Beginn||'')).valueOf() > (new Date(b.Beginn||'')).valueOf()  ) {
                    return -1
                  };
                  return 0
                }).map((I:DarlehenInfo, i:number)=><div key={i}>
                  {(new Date(I.Beginn||'')).toLocaleDateString('de-DE',{month:'2-digit',year:'2-digit', day:'2-digit'})} bis {(new Date(I.Ende||'')).toLocaleDateString('de-DE',{month:'2-digit',year:'2-digit', day:'2-digit'})}: <strong>{I.Zins}%</strong> | Ausgebe: {Libery.formatCurrency(I.Betrag||0)}  | Rückzahlung {I.Ratenzahl} á {Libery.formatCurrency(I.Rate||0)} ab {(new Date(I.ab||'')).toLocaleDateString('de-DE',{month:'2-digit',year:'2-digit', day:'2-digit'})}
                </div>)/** */}
              </PivotItem>}
            </Pivot>

          }
        </Panel>
        </>
    }
    closeKonto(){
      let sel = this.kontoSelection.getSelection();
      if (sel.length > 0){
          this.kontoSelection.setAllSelected(false)
      }
          this.setState({selectedKto:null})
      
    }
    changeSelectedKonto(attr:string, value:any, callback?:()=>void) {
        let konto = {...this.state.selectedKto}
        konto[attr]=value;
        this.setState({selectedKto:konto,
          lastSelection:(new Date).getTime()
        }, callback)
        
    }
}
class DarlehenForm extends React.Component<{
  darlehen:DarlehenInfo,
  key?:string|number,
  onChange:(darlehen:DarlehenInfo|any)=>void
}> {
  render(): React.ReactNode {
    let data:any = {...this.props.darlehen}
    if (data.Ende === null) {
      //data.Ende = '';
    }
    return  <Formular
    key={this.props.key}
    data={data}
    onChange={(workdata:any, element:any)=>{
      if (['number','spinedit'].indexOf(element.type) >=0 ){
        workdata[element.savePath] = parseFloat(workdata[element.savePath]);
      }
      this.props.onChange(workdata);
    }}
    className="darlehenInfo"
    elements={[
      {
        type:'date',
        label:'Beginn',
        savePath:'Beginn'
      },
      {
        type:'date',
        label:'Ende',
        savePath:'Ende'
      },
      {
        type:'date',
        label:'ab',
        savePath:'ab'
      },
      {
        type:'number',
        label:'zum',
        savePath:'zum',
        min:1,
        max:31
      },
      {
        type:'spinedit',
        label:'Zins',
        savePath:'Zins',
        min:0,
        percission:8
      },
      {
        type:'spinedit',
        label:'Rate',
        savePath:'Rate',
        min:0,
        percission:2
      },
      {
        type:'spinedit',
        label:'Ratenzahl',
        savePath:'Ratenzahl',
        min:0,
        percission:2
      },
      {
        type:'spinedit',
        label:'Betrag',
        savePath:'Betrag',
        min:0,
        percission:2
      },
    ]}

  />
  }
}

export interface KontoBestaendeProps {
  count:number,
  konten:any[],
  EchtzeitInput:Depotwert[]
  onLoad?:(konten:any[])=>void
  onZinsenBuchen?:(Buchung:IBuchung)=>void
}
interface Depotwert {
  ID:number,
  wert:number
}
export class KontoBestaende extends React.Component<KontoBestaendeProps,{
  liste:any[],
  groups:any[],
  depots:Depotwert[],
  darlehenInfoModal?:any
}>{
  private kontoSelection:Selection;
  state = {
    liste:[],
    groups:[],
    depots:[],
    darlehenInfoModal:''
  }
  constructor(props:KontoBestaendeProps){
    super(props);
    
    this.kontoSelection = new Selection({
      onSelectionChanged:()=>{
      }
    })
  }
  componentDidUpdate(prevProps:KontoBestaendeProps){
    if (this.props.count !== prevProps.count || this.props.konten !== prevProps.konten)
    this.componentDidMount()
  }
  componentDidMount(){
    API.post( '/fibu/query', {sql:'#Bestaende#'}).then(res=>{
      let Liste:any[]=[];
      let Groups:any[]=[];
      res.forEach((konto:any) => {
        if (Groups[Groups.length-1]?.name === konto.Gruppe) {
          Groups[Groups.length-1].count++
        } else {
          Groups.push({
            name:konto.Gruppe,
            startIndex:Liste.length,
            count:1,
            level:3
            
          })
        }
        konto.Echtzeit = konto.Bestand;
        Liste.push(konto);
      })
      let Groups2:any[] = [];
      let last = '';
      
      Groups.forEach((G:any)=>{
        let keys:string[] = G.name.split(' / ');
        if (keys[0] !== last) {
          Groups2.push({
            name:keys[0],
            startIndex:G.startIndex,
            count:0,
            level:2,
            children:[]
          })
        }
        Groups2[Groups2.length-1].count+=G.count;
        Groups2[Groups2.length-1].children.push(G)
        last = keys[0]
        //
      })
      Liste.forEach((item:any, index:number)=>{
        let K = this.props.EchtzeitInput.filter((I:Depotwert)=>I.ID===item.ID);
        if (K.length > 0){
          item.Echtzeit=  (K.map((I:Depotwert)=>I.wert).reduce((sum:number, item:number)=>sum+item,0))
        }
        if (item.typ === 9) {
          let KRprops:KreditrechnerProps = {
            konten:this.props.konten.filter((konto:any)=>konto.ID === item.ID),
            Zinsausgabe:{
              konto:item.ID,
              von:new Date((new Date).getFullYear()-1,11,31),
              bis:new Date()
            }
          }
          let KR:Kreditrechner = new Kreditrechner(KRprops);
          KR.preparePrevItems( {
            type:'Kontostandsentwicklung',
            konto: KRprops.Zinsausgabe?.konto,
            range:{
              type:'range',
              von: KRprops.Zinsausgabe?.von?.toISOString(),
              bis: KRprops.Zinsausgabe?.bis?.toISOString()
            }
          }).then((items:any)=>{
            if (items.length > 0) {
              item.Echtzeit+= items[items.length-1].Zinssumme;
              item.KR=items;
              let newListe = this.state.liste.map((konto:any)=>{
                if(item.ID === konto.ID) {
                  konto = item;
                }
                return konto;
              })
              this.setState({liste:newListe})
            }
          })
  
  
        }
      })
      this.setState( {liste:Liste, groups:[{
        name:"Gesamt",
        startIndex:0,
        count:Liste.length,
        level:1,
        children: Groups2
      }]},()=>{
        this.props.onLoad!(this.state.liste)
        API.get('/boerse/depot').then(res=>this.setState({depots:res}))
      })
    })
    
  } 
  render(){
    let Gesamt = this.state.liste.map((I:any)=>I.Bestand).reduce((sum, item)=>sum +item,0)
    const columns = [
      {
        name:"ID",
        maxWidth:20,
        minWidth:10,
        onRender:(item:any)=>{
            return (item.ID > 0 ? item.ID :'')
        }
      },
      {
        name:"Bezeichnung",
        minWidth:250,
        maxWidth:250,
        isMultiline:true,
        fieldName:'Bezeichnung2',
        onRender:(item:any)=>{
            return <span dangerouslySetInnerHTML={{__html:item.Bezeichnung.replace('(','<br />(')}} />
        }
      },
      {
        name:"Bestand",
        minWidth:70,
        maxWidth:70,
        onRender:(item:any)=>{
            return (<span key={"preis"+item.id} style={{display:'block',textAlign:'right',maxWidth:"104px", fontWeight:item.ID==0?'bold':''}}>
              {Libery.formatCurrency(item.Bestand)
                  }</span>)
        }
      },
      {
        name:"%",
        minWidth:70,
        maxWidth:70,
        onRender:(item:any)=>{
            return (<span key={"pz"+item.id} style={{display:'block',textAlign:'right', fontWeight:item.ID==0?'bold':''}}>
              {(new Intl.NumberFormat('de-DE', { unit:'percent', minimumFractionDigits:2,maximumFractionDigits:2})).format(item.Bestand/Gesamt*100)} %</span>)
        }
      },
      {
        name:"Echtzeit",
        minWidth:70,
        maxWidth:70,
        onRender:(item:any)=>{
            return (<span key={"preis"+item.id} style={{display:'block',textAlign:'right',maxWidth:"1000px", fontWeight:item.ID==0?'bold':''}}>
              {Libery.formatCurrency(item.Echtzeit)
                  }</span>)
        }
      },
      {
        name:"Echtzeit-Diff",
        minWidth:100,
        maxWidth:100,
        onRender:(item:any)=>{
            return (<span key={"preis"+item.id} style={{display:'block',textAlign:'right',maxWidth:"89px", fontWeight:item.ID==0?'bold':''}}
            onClick={()=>{
              let B:IBuchung = {...blankBuchung};
              B.Betrag = Math.round((item.Echtzeit-item.Bestand)*100)/100;
              B.Nach = item.ID
              
              B.Haushaltskonto = (config.FiBu.Zinskonto)
              B.Text = 'Zinsen '+(new Date).getFullYear();
              this.props.onZinsenBuchen!(B);
            }}>
              {Libery.formatCurrency(item.Echtzeit-item.Bestand)
                  }</span>)
        }
      },
      {
        name:"",
        minWidth:30,
        maxWidth:30,
        onRender:(item:any)=>{
          if (item.typ === 9 && item.KR?.length > 0) {
           
          return (<><IconButton styles={{root:{height:'24px'}}} iconProps={{iconName:'Info'}} onClick={()=>this.setState({darlehenInfoModal:item.KR})}/></>)
          }
            return (<></>)
        }
      }
    ].map((I:any, i:number)=>{
      if (typeof(I.fieldName)==='undefined') {
        I.fieldName = I.name
      }
      if (typeof(I.key)==='undefined') {
        I.key = i
      }
      return I;
    });
    let items = this.state.liste.map((item:any)=>{
      
      this.state.depots.forEach((Depot:Depotwert)=>{
        if (Depot.ID===parseFloat(item.ID)) {
          item.Echtzeit=Depot.wert
        }
      })
      return item;
    })

    const theme = getTheme();
    const classNames = mergeStyleSets({
      headerAndFooter: {
        borderTop: `1px solid ${theme.palette.neutralQuaternary}`,
        borderBottom: `1px solid ${theme.palette.neutralQuaternary}`,
        padding: '2px 1em',
        margin: `0`,
        background: theme.palette.neutralLighterAlt,
        // Overlay the sizer bars
        position: 'relative',
        display:'flex',
        alignItems:'center',
      },
      headerTitle: [
        theme.fonts.large,
        {
          padding: '4px 0'
        }
      ],
      headerLinkSet: {
        margin: '4px -8px'
      },
      headerLink: {
        margin: '0 8px'
      }
    });
    if (window.innerWidth <= 800){
      return <>
        <Accordion className="kontostaendeMobile" open={[0]}>
          {this.state.groups.map(this.renderGroupMobile)}
        </Accordion>
      {this.KRModal()}
      </>
    }
    return <div className='flex-grow flex-column' style={{position:'relative'}}>
      {this.KRModal()}
      <ListView
        items={items}
        groups={this.state.groups}
        columns={columns}
        selection={this.kontoSelection}
        selectionMode={SelectionMode.none}  
        className="kontobestaende"
        layoutMode={1}
        onRenderDetailsHeader={(props:IDetailsHeaderProps, defaultRender:IRenderFunction<IDetailsHeaderProps>)=> {
          return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
              {defaultRender({
        ...props,
        styles: {
          root: {
            selectors: {
              '.ms-DetailsHeader-cell': {
                whiteSpace: 'normal',
                textOverflow: 'clip',
                lineHeight: 'normal',
              },
              '.ms-DetailsHeader-cellTitle': {
                height: '100%',
                alignItems: 'center',
              },
              '.ms-GroupSpacer': {
                background:'red'
              }
            },
          },
        } 
      })}
            </Sticky>
          );
        }}
        groupProps={{
          onRenderHeader:  (props:any) => {
            const BestandSumme= this.state.liste
                                .filter((I:any, index:number)=>index >= props.group.startIndex &&  index < props.group.startIndex + props.group.count)
                                .map((I:any)=>I.Bestand)
                                .reduce((sum, item)=>sum +item)
            return (
              <div className={classNames.headerAndFooter} onClick={
                ()=> {
                  return  props.onToggleCollapse(props.group);
                }
              }>
              
              <Icon iconName={props.group.isCollapsed?'ChevronRightMed':'ChevronDownMed'} className="ms-IconExample" />
              
                <div style={{paddingLeft:((props.groupLevel)*15)+'px', width:'338px',boxSizing:'border-box'}} className={classNames.headerTitle}>{props.group.name}</div>
                
                <div style={{width:"8.5em", textAlign:'right', fontWeight:'bold'}}>
                  {Libery.formatCurrency(BestandSumme)}
                </div>
                <div style={{width:"7.5em",display:'block',textAlign:'right', fontWeight:1?'bold':''}}>
              {(new Intl.NumberFormat('de-DE', { unit:'percent', minimumFractionDigits:2,maximumFractionDigits:2})).format(BestandSumme/Gesamt*100)} %
              </div>

                <div style={{width:"7.5em", textAlign:'right', fontWeight:'bold'}}>
                  {Libery.formatCurrency(this.state.liste
                      .filter((I:any, index:number)=>index >= props.group.startIndex &&  index < props.group.startIndex + props.group.count)
                      .map((I:any)=>I.Echtzeit)
                      .reduce((sum, item)=>sum +item))}
                </div>

                <div style={{width:"9.1em", textAlign:'right', fontWeight:'bold'}}>
                  {Libery.formatCurrency(this.state.liste
                      .filter((I:any, index:number)=>index >= props.group.startIndex &&  index < props.group.startIndex + props.group.count)
                      .map((I:any)=>I.Echtzeit-I.Bestand)
                      .reduce((sum, item)=>sum +item))}
                </div>
              </div>
            );
          } /** */
        }}
      />
    </div>
  }
  KRModal=()=>{
//console.debug(theme.palette.themePrimary)
    const contentStyles = mergeStyleSets({
      container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width:'90vw'
      },
      header: [
        theme.fonts.xLargePlus,
        {
          flex: '1 1 auto',
          borderTop: `4px solid ${theme.palette.themePrimary}`,
          color: theme.palette.neutralPrimary,
          display: 'flex',
          alignItems: 'center',
          fontWeight: FontWeights.semibold,
          padding: '12px 12px 14px 24px',
        },
      ],
      heading: {
        color: theme.palette.neutralPrimary,
        fontWeight: FontWeights.semibold,
        fontSize: 'inherit',
        margin: '0',
      },
      body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'auto',
        position:'relative',
        minHeight:'80vh',
        display:'flex',
        flexDirection:'column',
        selectors: {
          p: { margin: '14px 0' },
          'p:first-child': { marginTop: 0 },
          'p:last-child': { marginBottom: 0 },
        },
      },
    });
    
    return  <Modal
    isOpen={this.state.darlehenInfoModal!=''}
    containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <h2 className={contentStyles.heading}>
          DarlehenInfo
        </h2>
        <IconButton
          styles={ {
            root: {
              color: theme.palette.neutralPrimary,
              marginLeft: 'auto',
              marginTop: '4px',
              marginRight: '2px',
            },
            rootHovered: {
              color: theme.palette.neutralDark,
            },
          }}
          iconProps={{iconName:'Cancel'}}
          ariaLabel="Close popup modal"
          onClick={()=>this.setState({darlehenInfoModal:''})}
        />
      </div>
      <div className={contentStyles.body}>
        <ListView
          items={this.state.darlehenInfoModal}
          
          columns={[
            {
              name:"Nr",
              minWidth:20,
              maxWidth:30
            },
            {
              name:"Datum",
              minWidth:50,
              maxWidth:70
            },
            {
              name:"Zinssatz",
              minWidth:50,
              maxWidth:70,
              onRender:(item:any)=>{
                  return (<span key={"preis"+item.id} style={{display:'block',textAlign:'right', fontWeight:item.ID==0?'bold':''}}>
                    {item.zinssatz} %</span>)
                      }
                    
                      
            },
            {
              name:"Tage",
              fieldName:"tage",
              minWidth:50,
              maxWidth:70
            }, /**
            {
              fieldName:"Haushaltskonto",
              name:"Haushaltskonto",
              minWidth:50,
              maxWidth:70
            }, */
            ...['Rate','Tilgung','Zins','Bestand', 'Zinssumme'].map((cl:any)=>{
              return {
              name:cl,
              minWidth:50,
              maxWidth:80,
              onRender:(item:any)=>{
                  return (<span key={"preis"+item.id} style={{display:'block',textAlign:'right',maxWidth:"104px", fontWeight:item.ID==0?'bold':''}}>
                    {Libery.formatCurrency(item[cl])
                        }</span>)
                      }
                    }
            })
          ].map((I:any,index:number)=>{
            if (typeof(I.fieldName)==='undefined') {
              I.fieldName = I.name
            }
            I.key = index;
            return I;
          })}
        />
      </div>
    
  </Modal>
  }
  renderGroupMobile=(G:IGroup, index?:number)=>{
    let items=this.state.liste.filter((I:any, idx:number) => idx >= G.startIndex && idx < G.startIndex+G.count);
    let EchtzeitDiff =  items.map((I:any)=>I.Echtzeit-I.Bestand).reduce((sum, item)=>sum +item,0);
    return (<AccordionItem {...{key:index,itemKey:index,contentStlye:{padding:0},headerText:
          <div style={{display:"flex", justifyContent:'space-between', alignItems:'center', width:'100%'}}>
            <Text variant="medium" style={{width:'12em', hyphens:'auto'}}>{G.name}</Text>
            <Text variant="medium">
              <span style={{display:'block', width:'8em',textAlign:'right'}}>{Libery.formatCurrency(items.map((I:any)=>I.Echtzeit).reduce((sum, item)=>sum +item,0))}</span>
                <span style={{display:'block', width:'8em',textAlign:'right'}}><Text style={{ color: (EchtzeitDiff > 0 ? '#008000':(EchtzeitDiff < 0 ?'#800000':''))}} variant="smallPlus">{Libery.formatCurrency(EchtzeitDiff)}</Text></span>
            </Text>
          </div>,
        }} >{(G.children||[]).length > 0 ? 
        <Accordion open={G.children?.map((I:any, idx:number)=>{
          
          return I.level > 2 ? null :idx
        })}>
          {G.children?.map(this.renderGroupMobile)}
        </Accordion>
        : <>
        {items.map((I:any)=>
          <div style={{display:"flex", justifyContent:'space-between', alignItems:'top', width:'100%', padding: '0.5em 0', borderBottom: `1px solid ${theme.palette.neutralQuaternary}`}}>

            <Text variant="smallPlus" style={{width:'15em', hyphens:'auto'}}><span dangerouslySetInnerHTML={{__html:I.Bezeichnung.replace('(','<br />(')}} /></Text>
            <div style={{width:'20px'}}>
          {(I.typ === 9 && I.KR?.length > 0) ?
           
           (<><IconButton styles={{root:{height:'24px'}}} iconProps={{iconName:'Info'}} onClick={()=>this.setState({darlehenInfoModal:I.KR})}/></>):<></>
          }
          </div>
            <Text variant="smallPlus">
            <span style={{display:'block', width:'8em',textAlign:'right'}}><Text>{Libery.formatCurrency(I.Echtzeit)}</Text></span>
                <span style={{display:'block', width:'8em',textAlign:'right',color: (I.Echtzeit-I.Bestand > 0 ? '#008000':(I.Echtzeit-I.Bestand < 0 ?'#800000':''))}}>{Libery.formatCurrency(I.Echtzeit-I.Bestand)}</span>
              </Text>
          </div>
          )}
        </>}</AccordionItem>)
  }
}