import React, {ChangeEvent} from 'react';

import { API, Libery,ListView,SpinEdit } from '../common';
import config from '../config';

import CanvasJSReact from '../common/canvasjs.react';
import { DetailsList, SelectionMode,DetailsListLayoutMode, DetailsRow, ScrollablePane, ScrollbarVisibility, Sticky, StickyPositionType, Icon, Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton, DatePicker, MarqueeSelection } from '@fluentui/react';

const Config = {
  apiBase:  '/boerse',
}


export default class Boerse extends React.Component<{},{
  items:any[],
  showAddDialog:any,
  history:any,
  hidden:any[]
}> {
  constructor(props:any){
    super(props);

    this.state = {
      items: [],
      showAddDialog:false,
      history:[],
      hidden:[]
    }
  }
  componentDidMount(){
    this._getItems();
  } 
  formatItems(){
    let result=[];
    for (let key in this.state.items) {
      let node = this.state.items[key];
      let base = {...node};
      base.isin = key;
      if (typeof(node.normal)!=='undefined') {
        for (let j in node.normal){
          base[j] = node.normal[j];
        }
        result.push(base);
      }
      base = {...node};
      base.isin = key;
      if (typeof(node.VL)!=='undefined') {
        for (let j in node.VL){
          base[j] = node.VL[j];
        }
        base.vl = 1;
        result.push(base);
      }
    }
    return result;
  }

  render() {
    
  let groups = [];
  let last = '';
  for (let i=0; i<this.state.items.length; i++){
    let key=Libery.formatDate(this.state.items[i].t1,'JJJJ');
    if (key !== last) {
      let heute = Libery.formatDate(Math.round((new Date()).getTime()/1000),'JJJJ');
      groups.push({ 
        key: key,
        name: key,
        startIndex:i,
        count: 1,
        isCollapsed:key!==heute
       })
    } else {
      groups[groups.length-1].count++;
    }
    last=key;
}
    let storeItem:any = {};
    if (this.state.showAddDialog) {
      let dl = {...this.state.showAddDialog};
      storeItem ={};
      ['isin', 'vl', 'datum', 'Kurs','Anteile','Umsatz'].forEach((key)=>{
        storeItem[key] = dl[key];
      })
    }    
    let data:any = [];
    for(let isin in this.state.history) {
      let pointsGewinnPro:any[] = [];
      let pointsGewinnEUR:any[] = [];
      
      if(typeof(this.state.history[isin]) === 'object'){
        this.state.history[isin].forEach((element:any) => {
            pointsGewinnPro.push({x:element.AnzeigeDatum_unix*1000, y:element.GewinnPro/100})
        });
        this.state.history[isin].forEach((element:any) => {
            pointsGewinnEUR.push({x:element.AnzeigeDatum_unix*1000, y:element.GewinnEur})
        }); /** */
      }
      data.push({				
          type: "line",
          showInLegend: "true",
          name:'% '+isin,
          xValueType: "dateTime",
          xValueFormatString: "DD.MM.YY",
          yValueFormatString: "#0.00%",
          dataPoints:pointsGewinnPro
      });
      data.push({				
          type: "line",
          axisYType: "secondary",
          showInLegend: "true",
          name:'€ '+isin,
          xValueType: "dateTime",
          xValueFormatString: "DD.MM.YY",
          yValueFormatString: "#0.00€",
          dataPoints:pointsGewinnEUR
      });
    }
    data = data.map((el:any,i:number)=>{
      el.visible = this.state.hidden.indexOf(i)===-1;
      return el;
    })
    let options1 = {			
      theme: "light2", 
      data: data,
      animationEnabled: true,
      toolTip:  {
        shared: true
      },
      legend:{
        verticalAlign:window.innerWidth > 1200 ? "center":"bottom",
        horizontalAlign:window.innerWidth > 1200 ? "right":"center",
        itemclick:(e:any)=>{
          let hidden = [...this.state.hidden];
          if(hidden.indexOf(e.dataSeriesIndex )===-1){
            hidden.push(e.dataSeriesIndex)
          } else {
            hidden = hidden.filter(idx => idx!==e.dataSeriesIndex)
          }
          this.setState({hidden:hidden})
        }
      },
			zoomEnabled: true,
      axisY: {
        valueFormatString: "#0.00%",
      },
      axisY2: {
        valueFormatString: "#0.00€",
      },
      axisX: {
        valueFormatString: "DD.MM.YY",
      },
      title:{
        text:"Gweinnentwicklung"
      }
   }
    return (
      <div className="Holiday component">
        <div className="main-view">
        <form name="upload"> {/*
        <input type="file" name="Vertragsdatei" 
          onChange={(e:ChangeEvent<HTMLInputElement>)=>{
            let file = new FileReader();
            file.readAsDataURL(e?.target?.files[0]);
            file.onload = (e2)=>{
              API.post(Config.apiBase+'/csv', {file:e2.target.result} )
              .then( (result) => {
                console.clear();
                result.forEach(storeItem=>{
                  storeItem = JSON.parse(JSON.stringify(storeItem))
                  API.post(Config.apiBase, storeItem)
                  .then( (result) => this._getItems())
              }) 
                document.upload.reset()
              }).catch( (error) => {
                console.error(error);
              });
            }
          }} 
        /> {/** */}
        </form>
        <div className="ScrollFixedHead"> 
					<ListView
						items={this.formatItems()}
						compact={true}
            groups={null}
            
						columns={ [
              
              {
                key: 'ISIN',
                name: 'ISIN',
                isResizable: true,
                isPadded: false,
                minWidth:80,
                maxWidth: 80,
                fieldName:'isin'
              } ,
              {
                key: 'column2',
                name: 'WKN',
                isResizable: true,
                isPadded: false,
                minWidth:50,
                maxWidth: 50,
                fieldName:'WKN',
              } ,
              {
                key: 'column3',
                name: 'Name',
                fieldName:'Name',
                isResizable: true,
                isPadded: false,
                minWidth:70,
                maxWidth: 180,
                isMultiline:true
              } ,
              {
                key: 'column3a',
                name: 'Kosten',
                fieldName:'Kosten',
                type:'number',
                unit:'€',
                digits:2,
                isResizadable: true,
                isPadded: false,
                minWidth:50,
                maxWidth: 60
              } ,
              {
                key: 'column3b',
                name: 'Kurs',
                fieldName:'aktuellerKurs',
                type:'number',
                unit:'€',
                digits:2,
                isResizadable: true,
                isPadded: false,
                minWidth:50,
                maxWidth: 60
              } ,
              {
                key: 'vl',
                name: 'VL',
                fieldName:'vl',
                digits:2,
                isResizadable: true,
                isPadded: false,
                minWidth:20,
                maxWidth: 20,
                onRender:(item:any)=>item.vl?'ja':'-'
              } ,
              {
                key: 'column4',
                name: 'Bestand',
                fieldName:'bestand',
                type:'number',
                unit:'',
                digits:4,
                isResizadable: true,
                isPadded: false,
                minWidth:30,
                maxWidth: 30
              } ,
              {
                key: 'column4',
                name: 'Wert',
                fieldName:'aktuellerKursWert',
                type:'number',
                unit:'€',
                digits:2,
                isResizadable: true,
                isPadded: false,
                minWidth:50,
                maxWidth: 60
              } ,
              {
                key: 'column4a',
                name: 'Gewinn',
                fieldName:'GewinnEur',
                type:'number',
                unit:'€',
                digits:2,
                isResizadable: true,
                isPadded: false,
                minWidth:50,
                maxWidth: 60
              } ,
              {
                key: 'column5',
                name: 'Prozent',
                fieldName:'Prozent',
                type:'number',
                unit:'%',
                digits:2,
                isResizadable: true,
                isPadded: false,
                minWidth:40,
                maxWidth: 50
              } , {
								name: (<span style={{fontSize:'18px',display:'block', textAlign:'center'}}><Icon iconName="Add"/></span>),
								fieldName: 'bezeichnung',
								minWidth: 30,
								maxWidth: 30,
								isResizable: true,
								isPadded: false,
								isMultiline:true,
								onRender:(item:any,index:number)=>{
									if(typeof(item.isin)!=='undefined')
										return (<span style={{fontSize:'0.7em !important', display:'block', textAlign:'center', cursor:'pointer'}} onClick={()=>{
                      item.index = index; 
                      item.Anteile=0;
                      item.Kurs =  Math.round(item.aktuellerKurs*(1+item.AA)*100)/100; 
                      item.Umsatz = 0;
                      item.datum=config.DatePicker.formatMysql(new Date());
                      this.setState({showAddDialog:item})
                    }}><Icon iconName="Add" /></span>)
								}
							} /** */
            ]}
            
						selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            onRenderDetailsFooter={(detailsFooterProps:any)=>{
              let rows = [];
              
              rows.push(<DetailsRow
              key="com"
              item={{
                aktuellerKursWert:this.formatItems().reduce((total,num) => {return total+num.aktuellerKursWert;},0),
                Kosten:this.formatItems().reduce((total,num) => {return total+num.Kosten;},0),
                GewinnEur:this.formatItems().reduce((total,num) => {return total+num.GewinnEur;},0),
                Prozent:this.formatItems().reduce((total,num) => {return total+num.GewinnEur;},0) / this.formatItems().reduce((total,num) => {return total+num.Kosten;},0)*100,
                bestand:null
              }}
              itemIndex={-1}
              {...detailsFooterProps}
              onRenderItemColumn={this.onRenderItemColumnRecords}
              />); /** */
              return rows
            }}
            onRenderItemColumn={this.onRenderItemColumnRecords}
					/>
          
          <div style={{margin:'1em 0'}}>
            <DefaultButton text="Zeige Alle" onClick={()=>this.setState({hidden:[]})} />
            <DefaultButton text="verstecke Alle" onClick={()=>this.setState({hidden:data.map((el:any,i:number)=>i)})} />
          </div>
            <div style={{margin:'1em 0'}}><CanvasJSReact.CanvasJSChart options={options1}   /></div> 
				<Dialog
					hidden={this.state.showAddDialog===false}
					dialogContentProps={{
						type: DialogType.normal,
            title:'Anteile Hinzufügen'
					}}
					modalProps={{
						isBlocking: true,
						containerClassName: 'addBörsenkauf'
					}}
					onDismiss={() => {
					}}
					>
            {this.state.showAddDialog!==false ?
            <div>
              <p style={{margin:'0 0 0.5em 0'}}>{this.state.showAddDialog.Name} <small style={{display:'block'}}> (WKN:{this.state.showAddDialog.WKN} / ISIN:{this.state.showAddDialog.isin})</small></p>
              
              <DatePicker {...config.DatePicker} 
            onSelectDate={(e) => {
              let data = {...this.state.showAddDialog}
              data.datum=config.DatePicker.formatMysql(e);
              this.setState({showAddDialog:data});
            }}
            label="Kaufdatum"
            formatDate={(date: Date | undefined) => { return date ? date.toLocaleDateString('de-DE',{ year: 'numeric', month: '2-digit', day: '2-digit' }) :'' }}
            value={this.state.showAddDialog && this.state.showAddDialog.datum ? new Date(this.state.showAddDialog.datum) : undefined}
          />
              {['Anteile', 'Kurs','Umsatz'].map((item)=>
								<SpinEdit
										value={this.state.showAddDialog[item==='kurs'?'aktuellerKurs':item]+''}
                    labelPosition={0}
										label={item}
										min={0}
										step={0.01}
			
										precision={10}
                    onChangeCustom={(value:number)=>{
                              
                      let data = {...this.state.showAddDialog}
                      data[item] = value;
                      if (item==='Umsatz') {
                        data['Anteile'] = data['Umsatz'] / data['Kurs'];
                      } else  {
                        data['Umsatz'] = data['Anteile'] * data['Kurs'];
                      }
                      data['Umsatz'] = Math.round(data['Umsatz']*100)/100;
                      data['Anteile'] = Math.round(data['Anteile']*10000)/10000;
                      data['Kurs'] = Math.round(data['Kurs']*10000)/10000;
                      this.setState({showAddDialog:data});
                    }}
								/>)}

<span style={{fontSize:'0.7em'}}>{JSON.stringify(storeItem)}</span>
            </div>
            :''}
					<DialogFooter>
						<PrimaryButton onClick={(e)=>{
              API.post(Config.apiBase, storeItem)
                .then( (result) => {
                  this.setState({showAddDialog:false},()=>this._getItems())
                });
						}} text="Speichern" disabled={false} />
						<DefaultButton onClick={(e)=>{
              this.setState({showAddDialog:false})
						}} text="abbrechen" />
					</DialogFooter>
				</Dialog>
        </div>
        
      </div>
      </div>
    );
    
  }
  //API-Aufruf, um die die Kunden abzurufen
  _getItems(){ 
    API.get(Config.apiBase )
      .then( (result) => {
        this.setState({
          items: result
        }); 
    }).catch( (error) => {
      console.error(error);
    });
    API.get(Config.apiBase+'/history' )
      .then( (result) => {
        this.setState({
          history: result
        }); 
    }).catch( (error) => {
      console.error(error);
    });
  }
  onRenderItemColumnRecords(item:any, index:number, column:any){
    if (index){}
    let columnType =typeof(column.type)!=='undefined' ? (column.type) : '';
    let columnUnit =typeof(column.unit)!=='undefined' ? ' '+(column.unit) : '';
    let inhalt='';
    let textAlign ='right';
    switch (columnType){
      case 'number':
        inhalt = item[column.fieldName]!==null?Libery.formatNumber(item[column.fieldName],column.digits,',','.')+columnUnit:'';
      break;
      case 'date':
        inhalt = Libery.formatDatum(item[column.fieldName],column.format);
      break;
      default: 
        inhalt = item[column.fieldName];
        textAlign ='left';
      break;
      
    }
    return (<span style={{
                display: 'block',
                textAlign: textAlign==='left'?'left':'right'
            }}>{inhalt}</span>)
  }

  
}

