import React from "react";
import Fibu from "../Fibu";

import config from '../../config';
import { ListView, Libery } from "../../common";
import { DatePicker, Text } from "@fluentui/react";
export interface PreviewListProps {
    parent:Fibu
}
export class PreviewList extends React.Component<PreviewListProps>{
    render(): React.ReactNode {
     
    return <> 
    
        <div style={{display:'flex'}}>
            <DatePicker {...config.DatePicker} 
                onSelectDate={(e: Date | null | undefined) => {
                if (e!==null && e!==undefined) {
                    this.props.parent.setState({vorschauVon:e},()=>this.props.parent.getVorschaubuchungen())
                }
                }}
                label="von"
                value={this.props.parent.state.vorschauVon}
            />
            <DatePicker {...config.DatePicker} 
                onSelectDate={(e: Date | null | undefined) => {
                if (e!==null && e!==undefined) {
                    this.props.parent.setState({vorschauBis:e},()=>this.props.parent.getVorschaubuchungen())
                }
                }}
                label="bis"
                styles={{root:{marginLeft:'1em'}}}
                value={this.props.parent.state.vorschauBis}
            />
        </div>
        <div style={{position:'relative', minHeight:'200px'}} className='flex-column flex-grow'>
            <ListView
            items={this.props.parent.state.previewItems}
            className="striped"
            columns={[
            {
                name:'Datum',
                onRender:(item:any)=><span >{config.DatePicker.formatDate(new Date(item.Datum))}</span>,
                minWidth:55,
                maxWidth:55,
                className:'sticky'
            },
            {
                name:'⮚',
                onRender:(item:any)=><span 
                                    title={this.props.parent.state.konten.filter((i:any)=>i.ID===item.Von).map((k:any)=>k.Bezeichnung).join('')}
                                    style={{display:'block',textAlign:'center'}}
                                    >{item.Von === 51 ? '⮚' : item.Von}</span>,
                minWidth:10,
                maxWidth:10
            },
            {
                name:'⮚',
                onRender:(item:any)=><span 
                                    title={this.props.parent.state.konten.filter((i:any)=>i.ID===item.Nach).map((k:any)=>k.Bezeichnung).join('')}
                                    style={{display:'block',textAlign:'center'}}
                                    >{item.Nach === 51 ? '⮚' : item.Nach}</span>,
                minWidth:10,
                maxWidth:10,
            },{
                name:'Betrag',
                minWidth:50,
                maxWidth:50,
                onRender:((item:any)=>{
                return  (<span key={"preis"+item.id} style={{display:'block',textAlign:'right',maxWidth:"104px"}}>
                {Libery.formatCurrency(item?.Betrag) }</span>)
                    })
            },{
                name:'Gesamt',
                minWidth:70,
                maxWidth:70,
                onRender:((item:any)=>{
                return  (<span key={"preis"+item.id} style={{display:'block',textAlign:'right',maxWidth:"104px"}}>
                {Libery.formatCurrency(this.props.parent.state.konten.filter((i:any)=>[4,5,7,9,16].indexOf(i.Typ) >=0 && i.Aktiv===1 ).map((k:any)=>item?.konten?.[k.ID]).reduce((item,sum) => sum+item,0))}</span>)
                    })
            },
            ...this.props.parent.state.konten.filter((i:any)=>[4,5,7,9,16].indexOf(i.Typ) >=0 && i.Aktiv===1 ).map((k:any)=>{
            return {
                name:k?.Bezeichnungkurz+' ('+k.ID+')',
                minWidth:70,
                maxWidth:70,
                onRender:((item:any)=>{
                return  (<span key={"preis"+item.id} style={{display:'block',textAlign:'right',maxWidth:"104px", fontWeight:item.Von==k.ID || item.Nach==k.ID?'bold':''}}>
                {Libery.formatCurrency(item?.konten?.[k.ID])
                    }</span>)
                })
            }
            }),
        {name:""}]}
            selectionMode={1}  
            layoutMode={1}
            selection={this.props.parent._selection}
        />
        </div>
    </>   
    }
}