import React from "react";
import { Modal } from "@fluentui/react";


export class MakeFoto extends React.Component<{
    hidden:boolean,
    onTakePhoto:(dataURI:string) => void
}> {
    render(): React.ReactNode {
        return  <Modal isOpen={!this.props.hidden}>
        </Modal>
    }
}