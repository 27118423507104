import React from "react";
import { TextField, DatePicker, TagPicker, TagItem, Text,Link, Label,Position, PrimaryButton, DefaultButton, ITag, IDropdownOption, IconButton, Toggle } from "@fluentui/react";
import { IKontoDropdownOption, KontoDropdown } from "./konten";
import { SpinEdit } from "../../common/libery";
import config from '../../config';
import Fibu from "../Fibu";
export interface IZeitraum{
  von:Date|null,
  bis:Date|null
}
export interface IBuchung {
  ID?:number,
  Von:number,
  Nach:number,
  Haushaltskonto:number,
  Datum:Date,
  Betrag:number|string,
  Text:string,
  active?:number,
  monate?:any[]
  tage?:any[];
  prevID?:number,
  zeitraum:any[]
}
export const blankBuchung:IBuchung = {
  Von:51,
  Nach:51,
  Haushaltskonto:0,
  Datum:(new Date()),
  Betrag:'',
  Text:'',
  zeitraum:[]
}
export const blankVorschauBuchung:IBuchung = {
  Von:51,
  Nach:51,
  Haushaltskonto:0,
  Datum:(new Date()),
  Betrag:0,
  Text:'',
  monate:[1,2,3,4,5,6,7,8,9,10,11,12],
  tage:[],
  zeitraum:[],
}
export interface IBuchungProps {
  parent:Fibu,
  item:IBuchung,
  defaultItem:IBuchung,
  type:string,
  geldKonten:any[],
  haushaltsKonten:any[],
  className?:string,
  onSave:()=>void
}

export class Buchung extends React.Component<IBuchungProps> {
    render (){
        const MonthItems:ITag[]= config?.DatePicker?.strings.shortMonths.map((item,index) =>{
          return {key:index+1, name: item}
        });
        const days:ITag[]=  Array.from(Array(31).keys()).map((item,index) =>{
          return {key:index+1, name: String(item+1)}
        });
        
        const selMonth = (this.props.item?.monate||[]);
        const selDays = (this.props.item.tage||[]);
        const TagItemStyles = {root:{minWidth:'0em'},close:{padding:0,margin:0},text:{padding:0,minWidth:'1em', margin:'0 0 0 5px'}};
        return <div className={"BuchungForm "+this.props.className!}>
        
        {
          {
            buchen:<DatePicker {...config.DatePicker} 
                      onSelectDate={(e) => {
                        let buchungItem = {...this.props.item}
                        if (e!==null && e!==undefined)
                          buchungItem.Datum=(e);
                        this.props.parent.setState({buchungItem:buchungItem})
                      }}
                      label="Datum"
                      formatDate={(date?:Date) => { return date?.toLocaleDateString('de-DE',{ year: 'numeric', month: '2-digit', day: '2-digit' }) || ''  }}
                      value={this.props.parent.state && this.props.item.Datum ? new Date(this.props.item.Datum) : undefined}
                    />,
            vorschau:<>
            <TextField
              label="ID"
              disabled
              value={String(this.props.item.ID||0)}
            />
            <Toggle label="Status" onText="aktiv" offText="inaktiv" checked={this.props.item.active === 1} 
            onChange={(event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined)=>{
              let buchungItem = {...this.props.item}
              buchungItem.active = checked ? 1 : 0;
              this.props.parent.setState({buchungItem:buchungItem})
            }} />
            <Label>Zeitraum
              &nbsp;| <Link onClick={()=>{
                let item = {...this.props.item}
                item.zeitraum.push({von:new Date, bis:null})
                this.props.parent.setState({buchungItem:item})
              }}>hinzufügen</Link>
              &nbsp;| <Link onClick={()=>{this.props.parent.setState({buchungItem:{...this.props.item,zeitraum:[]}})}}>leeren</Link>
            </Label>
              {this.props.item.zeitraum?.map((zeitraum:any, index:number) => {
                return <div key={index} style={{display:'flex', width:'320px',justifyContent:'space-between', alignItems:'flex-end'}}>
                  <DatePicker
                    {...config.DatePicker}
                    label={index===0?"von":''}
                    value={(zeitraum.von && new Date(zeitraum.von)) || null}
                    onSelectDate={(e) => {
                      let buchungItem = {...this.props.item}
                      if (e!==null && e!==undefined)
                        buchungItem.zeitraum[index].von=config.DatePicker.formatMysql(e);
                      this.props.parent.setState({buchungItem:buchungItem})
                    }}
                    styles={{root:{minWidth:'130px',maxWidth:'150px'}}}
                  />
                  <IconButton 
                    iconProps={{iconName:'DoubleChevronLeftMedMirrored'}} 
                    style={{paddingBottom:'10px'}}
                    onClick={()=>{
                      let buchungItem = {...this.props.item}
                        buchungItem.zeitraum[index].bis=buchungItem.zeitraum[index].von;
                      this.props.parent.setState({buchungItem:buchungItem})
                    }}
                    />
                  <DatePicker
                    {...config.DatePicker}
                    label={index===0?"bis":''}
                    value={(zeitraum.bis && new Date(zeitraum.bis)) || null}
                    onSelectDate={(e) => {
                      let buchungItem = {...this.props.item}
                      if (e!==null && e!==undefined)
                        buchungItem.zeitraum[index].bis=config.DatePicker.formatMysql(e);
                      this.props.parent.setState({buchungItem:buchungItem})
                    }}
                    styles={{root:{minWidth:'130px',maxWidth:'150px'}}}
                  />
                  <IconButton iconProps={{iconName:'delete'}} style={{paddingBottom:'10px'}} onClick={()=>{
                      
                      let buchungItem = {...this.props.item}
                        buchungItem.zeitraum.splice(index,1);
                      this.props.parent.setState({buchungItem:buchungItem})

                  }} />
                </div>
              })}
            <Label>Monate
              &nbsp;| <Link onClick={()=>{this.props.parent.setState({buchungItem:{...this.props.item,monate:MonthItems?.map(I=>I.key)}})}}>alle</Link>
              &nbsp;| <Link onClick={()=>{this.props.parent.setState({buchungItem:{...this.props.item,monate:[]}})}}>keine</Link>
            
            </Label>
            <TagPicker
              onResolveSuggestions={(filterText:string, tagList?:ITag[] ):ITag[]=> {
                return filterText.length 
                ? MonthItems
                .filter((tag) => {
                  return tag.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0 
                })
                : MonthItems;
              }}
              onRenderItem={(item)=>{
                return <TagItem {...item} styles={TagItemStyles}><Text key={item.key}>
                  {item.item.name}
                  </Text></TagItem>;
              }}
              onRenderSuggestionsItem={(item)=>{
                return <span style={{display:'block', padding:'5px', textAlign:'left'}}>{item.name}</span>
              }}
              pickerSuggestionsProps={{
                suggestionsHeaderText: 'Vorgeschlagene Kunde',
                noResultsFoundText: 'Keine entsprechende Kunden  gefunden'
              }}
              selectedItems={MonthItems.filter(I=>selMonth.indexOf(I.key)>=0)}
              onChange={items=>{
                let buchungItem = {...this.props.item}
                buchungItem.monate = items?.map(I=>I.key);
                this.props.parent.setState({buchungItem:buchungItem})
              }}
            />
            <Label>Tage 
              &nbsp;| <Link onClick={()=>{this.props.parent.setState({buchungItem:{...this.props.item,tage:days?.map(I=>I.key)}})}}>alle</Link>
              &nbsp;| <Link onClick={()=>{this.props.parent.setState({buchungItem:{...this.props.item,tage:[]}})}}>keine</Link>
            
            </Label>
            <TagPicker
              onResolveSuggestions={(filterText, tagList)=> {
                let rt = filterText.length 
                ? days
                .filter((tag) => {
                  return tag.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0 
                })
                : days;
                return rt;
              }}
              onRenderItem={(item)=>{
                return <TagItem {...item} styles={TagItemStyles}><Text key={item.key}>
                  {item.item.name}
                  </Text></TagItem>;
              }}
              onRenderSuggestionsItem={(item)=>{
                return <span style={{display:'block', padding:'5px', textAlign:'left'}}>{item.name}</span>
              }}
              pickerSuggestionsProps={{
                suggestionsHeaderText: 'Vorgeschlagene Kunde',
                noResultsFoundText: 'Keine entsprechende Kunden  gefunden'
              }}
              selectedItems={days.filter(I=>selDays.indexOf(I.key)>=0)}
              onChange={(items?:ITag[])=>{
                let buchungItem = {...this.props.item}
                buchungItem.tage = items?.map(I=>I.key);
                this.props.parent.setState({buchungItem:buchungItem})
              }}
            />
            </>
          }[this.props.type]
        }
        <KontoDropdown 
            label={'von'}
            options={this.props.geldKonten}
            onChangeCustom={(item:IDropdownOption | undefined)=>{
              let buchungItem:IBuchung = {...this.props.item}
              buchungItem.Von =  Number(item?.key);
              this.props.parent.setState({buchungItem:buchungItem})
            }}
            selectedKey={this.props.item.Von}
          />
          <KontoDropdown 
            label={'nach'}
            options={this.props.geldKonten}
            onChangeCustom={(item:IDropdownOption | undefined)=>{ 
              let buchungItem:IBuchung = {...this.props.item}
              buchungItem.Nach =  Number(item?.key);
              this.props.parent.setState({buchungItem:buchungItem})
            }}
            selectedKey={this.props.item.Nach}
          />
          <KontoDropdown 
            label={'Haushaltskonto'}
            options={this.props.haushaltsKonten}
            onChangeCustom={(item:IDropdownOption | undefined)=>{
              let buchungItem:IBuchung = {...this.props.item}
              buchungItem.Haushaltskonto =  Number(item?.key);
              this.props.parent.setState({buchungItem:buchungItem})
            }}
            selectedKey={this.props.item.Haushaltskonto}
          />
          <SpinEdit
            label={'Betrag'} 
            labelPosition={Position.top}
            step={0.01}
            precision={2}
            value={String(this.props.item.Betrag) || ""}
            onChangeCustom={(value:number) => {
                
              let buchungItem = {...this.props.item}
              buchungItem.Betrag = Math.round((value)*100)/100;
              this.props.parent.setState({buchungItem:buchungItem})
            }}
          />
          <TextField
            label="Buchungstext"
            value={this.props.item.Text || ""}
            onChange={(e,value) => {
                
              let buchungItem:IBuchung = {...this.props.item}
              buchungItem.Text = value || '';
              this.props.parent.setState({buchungItem:buchungItem})
            }}
            
          />
          {/** *}
          <div>{JSON.stringify(blankBuchung)}</div>
          <div>{JSON.stringify(this.props.item)}</div>
          {/** */}
          <div style={{paddingTop:'0.5em'}}>
          <PrimaryButton
              text="Buchen"
              disabled={JSON.stringify(this.props.item) === JSON.stringify(blankBuchung) }
              style={{marginRight:'0.5em'}}
              onClick={()=>this.props.onSave()}
          />
          {JSON.stringify(this.props.item) !== JSON.stringify(this.props.defaultItem) &&
            <DefaultButton 
              text="Buchung zurücksetzen"
              onClick={()=>this.props.parent.setState({buchungItem:this.props.defaultItem})}
            />
          }</div>
     </div>
    }
}