import React from "react";
import { TextField, DatePicker, TagPicker, TagItem, Text, IDropdownOption,Position, Pivot, PivotItem, IChoiceGroupOption, ChoiceGroup, Spinner, Dropdown, Toggle } from "@fluentui/react";
import { IKontoDropdownOption, KontoDropdown } from "./konten";
import { SpinEdit, API, ListView, Libery } from "../../common";
import config from '../../config';

interface AuswertungProps{
  geldKonten:any[],
  haushaltsKonten:any[],
  count:number
}
interface AuswertungState {
  RangeType:string;
  DocType:string;
  monat:number;
  jahr:number;
  quartal:number,
  haushaltskonto:number,
  konto:number,
  von:Date,
  bis:Date,
  src?:any,
  docTypes:any[],
  loading:boolean,
  preview:boolean
}
export class Auswertung extends React.Component<AuswertungProps,AuswertungState> {
  constructor(props:any){
    super(props);
    let D = (new Date);
    let M =D.getMonth()+1;
    this.state = {
      RangeType:'YM',
      DocType:'',
      docTypes:[],
      monat:M,
      quartal:Math.ceil(M/3),
      jahr:D.getFullYear(),
      von:new Date,
      bis:new Date,
      loading:false,
      preview:false,
      haushaltskonto:0,
      konto:0
    }
  }
  componentDidMount(): void {
    this.getDoc();
    this.getDocTypes();
  }
  componentDidUpdate(prevProps: Readonly<AuswertungProps>, prevState: Readonly<AuswertungState>, snapshot?: any): void {
    if (
         prevState.RangeType !== this.state.RangeType
      || prevState.monat !== this.state.monat
      || prevState.DocType !== this.state.DocType
      || prevState.quartal !== this.state.quartal
      || prevState.jahr !== this.state.jahr
      || prevState.von !== this.state.von
      || prevState.bis !== this.state.bis
      || prevState.haushaltskonto !== this.state.haushaltskonto
      || prevState.konto !== this.state.konto
      || prevState.preview !== this.state.preview
    ) {
      this.getDoc();
    }
  }
    render (){
      return <div className="wk-grid flex-grow thinspace" style={{alignItems:'stretch', display:'flex', flexWrap:'wrap'}}>
          <div className="wk-u-1 wk-u-m-1-2 wk-u-l-1-5">
            <Dropdown
              options={this.state.docTypes.map((T:any)=>{
                return {key:T.id, text:T.title}
              })}
              onChange={(e:any,option?: IDropdownOption<any> | undefined)=>{
                this.setState({DocType:String(option?.key||'')})
              }}
              selectedKey={this.state.DocType}
              disabled={this.state.loading}
              label="Auswertungstyp"
            />
            <Dropdown
              label="Zeitraumtyp"
              options={[
                {key:"all",text:"komplett"},
                {key:"range",text:"Zeitraum"},
                {key:"Y",text:"Jahr"},
                {key:"YQ",text:"Jahr / Quartal"},
                {key:"YM",text:"Jahr / Monat"}
              ]}
              selectedKey={this.state?.RangeType}
              onChange={(ee?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,option?: IDropdownOption | undefined)=>this.setState({RangeType:String(option?.key)||''})}
              className="selectAuswertungRangeType"
              disabled={this.state.loading}
            />
            {this.state.RangeType.indexOf("Y")>=0?
            <SpinEdit        
              label={'Jahr'} 
              labelPosition={Position.top}
              disabled={this.state.loading}
              step={1}
              precision={0}
              value={String(this.state.jahr)}
              onChangeCustom={(value:number) =>this.setState({jahr: value})}
            />:<></>}
            {this.state.RangeType.indexOf("M")>=0?
            <SpinEdit        
              label={'Monat'} 
              labelPosition={Position.top}
              disabled={this.state.loading}
              step={1}
              min={1}
              max={12}
              precision={0}
              value={String(this.state.monat)}
              onChangeCustom={(value:number) =>this.setState({monat: value},()=>this.setState({quartal:Math.ceil(this.state.monat/3)}))}
            />:<></>}
            {this.state.RangeType.indexOf("Q")>=0?
            <SpinEdit        
              label={'Quatal'} 
              labelPosition={Position.top}
              disabled={this.state.loading}
              step={1}
              min={1}
              max={4}
              precision={0}
              value={String(this.state.quartal)}
              onChangeCustom={(value:number) =>this.setState({quartal: value})}
            />:<></>}
            {this.state.RangeType==='range' ?<>
            <DatePicker {...config.DatePicker} 
              onSelectDate={(e: Date | null | undefined) => {
                if (e!==null && e!==undefined) {
                  this.setState({von:e})
                }
              }}
              label="von"
              disabled={this.state.loading}
              value={this.state.von}
            />
            <DatePicker {...config.DatePicker} 
              onSelectDate={(e: Date | null | undefined) => {
                if (e!==null && e!==undefined) {
                  this.setState({bis:e})
                }
              }}
              label="bis"
              disabled={this.state.loading}
              value={this.state.bis}
            /></>:<></>}
            <Toggle label="Buchungsauswahl" onText="mit Vorschaubuchungen" offText="mit Vorschaubuchungen" checked={this.state.preview} disabled={this.state.loading}
             onChange={(e:React.MouseEvent<HTMLElement, MouseEvent>, checked?:boolean)=>this.setState({preview:checked===true})} />
            {this.state.DocType === 'Buchliste' 
            ?<>
              <Dropdown
                label="Haushaltskonto"
                options={[{key:'0',text:'-- keine Haushaltsfilterung'},
                ...(this.props.haushaltsKonten||[]).map((k:any)=>{
                  return {key:k.ID, text:k.Bezeichnung}
                })]}
                selectedKey={this.state.haushaltskonto}
                onChange={(e:any,option?: IDropdownOption<any> | undefined)=>{
                  this.setState({haushaltskonto:Number(option?.key||'0') ||0})
                }}

              />
            </>
            :<></>}
            {this.state.DocType === 'Kontostandsentwicklung' 
            ?<>
              <Dropdown
                label="Konto"
                options={[
                ...(this.props.geldKonten||[]).map((k:any)=>{
                  return {key:k.ID, text:k.Bezeichnung}
                })]}
                selectedKey={this.state.konto}
                onChange={(e:any,option?: IDropdownOption<any> | undefined)=>{
                  this.setState({konto:Number(option?.key||'0') ||0})
                }}

              />
            </>
            :<></>}

          </div>
          <div className="wk-u-1 wk-u-m-1-2 wk-u-l-4-5 flex-column">
            <div className="flex-grow" style={{display:'flex',alignItems:'center', justifyContent:'space-around'}}>
              {this.state.loading
              ?<Spinner styles={{circle:{height:'150px',width:'150px', borderWidth:'3px'}}} label="Lade gewünschten Bericht" />
              :<iframe src={this.state.src}  style={{width:'100%',height:'100%',minHeight:'400px', border:0}}/>}
            </div>

          </div>
        </div>
    }
    getRange(){
      return {
        type : this.state.RangeType,
        monat : this.state.monat,
        quartal : this.state.quartal,
        jahr: this.state.jahr,
        von: this.state.von?.toISOString(),
        bis: this.state.bis?.toISOString()
      }
    }
    getDoc(){
      this.setState({loading:true},
         ()=> API.download('/fibu/pdf',{
            "type":this.state.DocType,
            haushaltskonto: this.state.haushaltskonto,
            konto: this.state.konto,
            preview: this.state.preview,
            "range":this.getRange()
          }).then((doc:any)=>{
            const  reader = new FileReader();
            reader.addEventListener("load", () => {
              this.setState({loading:false, src:reader.result||''}/*,()=>setTimeout(()=>this.getDoc(),30000)*/)
            }, false);
            reader.readAsDataURL(doc);
          })
      )
    }
    getDocTypes(){
      
      API.get('/fibu/pdf/types').then((docs:any)=>{
        this.setState({docTypes:docs, DocType:docs.map((doc:any)=>doc.id)[0]})
      });
    }
}

export enum UeberschussType {
  lfdY,
  prevY,
  lfdM,
  prevM
}

interface UeberschussProps {
  type: UeberschussType
}
export class Ueberschuss extends React.Component<UeberschussProps,{
  Ueberschuss:number
}>{
  state = {
    Ueberschuss:0
  }
  componentDidMount(): void {
    API.post('/fibu/ueberschuss',this.props).then((result:any)=>{
      this.setState({Ueberschuss:parseFloat(result)})
    }) 
  }
  render(): React.ReactNode {
    let color = '#000';
    if (this.state.Ueberschuss < 0 ) {
      color = '#800000';
    }
    if (this.state.Ueberschuss > 0 ) {
      color = '#008000';
    }
    return <div style={{display: 'flex', alignItems:'center', justifyContent:'space-between'}}>
    {[
      <Text variant='large'>lfd. Jahr</Text> ,
      <Text variant='large'>Vorjahr</Text>,
      <Text variant='large'>lfd. Monat</Text>,
      <Text variant='large'>Vormonat</Text>
    ][this.props.type]}


    
    <Text variant="xLarge" style={{color:color}}>{config.formatCurrency(this.state.Ueberschuss)}</Text>
    </div>
  }
}
interface AusgabeInDaysProps {
  after: number,
  before:number
}
export class AusgabeInDays extends React.Component<AusgabeInDaysProps,{
  AusgabeInDays:number
}>{
  state = {
    AusgabeInDays:0
  }
  componentDidMount(): void {
    API.post('/fibu/AusgabeInDays',this.props).then((result:any)=>{
      this.setState({AusgabeInDays:parseFloat(result)})
    }) 
  }
  render(): React.ReactNode {
    return <div style={{display: 'flex', alignItems:'center', justifyContent:'space-between'}}>

    
    <Text variant="xxLarge" style={{color:'#800000'}}>{config.formatCurrency(this.state.AusgabeInDays)}</Text>
    </div>
  }
}

export interface MaxHistoryItem{
  Datum:Date,
  Summe:number
}

export class MaxHistory extends React.Component<{
  count:number,
  items:MaxHistoryItem[]
}>{
  render(): React.ReactNode {
    const columns = [
      {
        name:"Datum",
        maxWidth:20,
        minWidth:10,
        onRender:(item:MaxHistoryItem)=>{
            return config.DatePicker.formatDate(new Date(item.Datum))
        }
      },
      {
        name:"Bestand",
        minWidth:70,
        maxWidth:70,
        onRender:(item:any)=>{
            return (<span key={"preis"+item.id} style={{display:'block',textAlign:'right',maxWidth:"104px", fontWeight:item.ID==0?'bold':''}}>
              {Libery.formatCurrency(item.Summe)
                  }</span>)
        }
      }
    ].map((I:any)=>{
      if (typeof(I.fieldName)==='undefined') {
        I.fieldName = I.name
      }
      return I;
    })
    let items = JSON.parse(JSON.stringify(this.props.items))
    return <ListView
      items={items.reverse()}
      columns={columns}
    />
  }
}