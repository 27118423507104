import React from "react";
import { DefaultButton,TextField, Dropdown,Panel,PanelType, Sticky, IDetailsFooterProps,Selection, SelectionMode, CommandBar, Toggle, Position, PrimaryButton, DetailsRow } from "@fluentui/react";
import {ListView,SpinEdit,API, Libery, DBNavigator } from "../../common";
import Formular from "../../common/Formular";
import config from "../../config";
import { Kreditrechner, KreditrechnerProps } from "./rerchner";

interface  UmlaufProps {
  umlauf:any[],
  count:number,
  onSave:(item:any)=>void
}
interface UmlaufState {
  selectedItem:any,
  initSelectedItem:any,
  lastSelection:number,
  darlehenInfoIndex:number
}
export interface DarlehenInfo {
  Beginn?:Date | null | undefined,
  Ende?:Date | null | undefined,
  ab?:Date | null | undefined,
  Betrag?:number,
  Ratenzahl?:number,
  zum?:number,
  Rate?:number,
  Zins?:number
}
export class Umlauf extends React.Component<UmlaufProps, UmlaufState > {
  private umlaufSelection:Selection;
  props:UmlaufProps;
  state:UmlaufState = {
    selectedItem : null,
    initSelectedItem: null,
    lastSelection:0,
    darlehenInfoIndex:0
  }
  
    
  constructor(props:UmlaufProps){
    super(props);
    this.props = props;
    
    this.umlaufSelection = new Selection({
      onSelectionChanged:()=>{
        let sel = this.umlaufSelection.getSelection();
        if(sel.length > 0){
          this.setState({
            selectedItem:JSON.parse(JSON.stringify(sel[0])), 
            initSelectedItem:sel[0], 
            lastSelection:(new Date).getTime(),
            darlehenInfoIndex:0
          })
        }
      }
    })
  }
    render(){
       let Items:any[]= this.props.umlauf||[];

        return <>
        <CommandBar
            items={[
                {
                  key:"new",
                    name:"neuer Eintrtag",
                    iconProps: { iconName: 'Add' },
                    onClick:()=>this.setState({
                      selectedItem:{ID:0,Typ:0},
                      darlehenInfoIndex:0
                    })
                }
            ]}
        />
      <ListView
        items={Items}
        layoutMode={1}
        columns={[
          {
            name:"Text",
            isMultiline:true
          },
          {
            name:"Menge",
            minWidth:45,
            maxWidth:45,
            onRender:(item:any)=>{
                return (item.Menge>=0? <span key={"preis"+item.id} style={{display:'block',textAlign:'center', fontWeight:item.ID==0?'bold':''}}>
                  {item.Menge}</span>:<></>)
            }
          },
          {
            name:"Basis",
            minWidth:40,
            maxWidth:40,
            onRender:(item:any)=>{
                return (item.Basis>0? <span key={"preis"+item.id} style={{display:'block',textAlign:'right',maxWidth:"104px", fontWeight:item.ID==0?'bold':''}}>
                  {Libery.formatCurrency(item.Basis)
                      }</span>:<></>)
            }
          },
          {
            name:"Bestand",
            minWidth:50,
            maxWidth:50,
            onRender:(item:any)=>{
                return (<span key={"preis"+item.id} style={{display:'block',textAlign:'right',maxWidth:"104px", fontWeight:item.ID==0?'bold':''}}>
                  {Libery.formatCurrency( item.Menge*item.Basis || item.Summe)
                      }</span>)
            }
          },
        ].map((I:any)=>{
          if (typeof(I.fieldName)==='undefined') {
            I.fieldName = I.name
          }
          return I;
        })}
        selection={this.umlaufSelection}
        selectionMode={SelectionMode.single}
        
        onRenderDetailsFooter={(detailsFooterProps: IDetailsFooterProps): JSX.Element => {
          return (
            <DetailsRow
              {...detailsFooterProps}
              columns={detailsFooterProps.columns}
              item={{ID:0,Summe:Items.reduce((sum:any,item:any)=>sum+item.Menge*item.Basis,0)}}
              itemIndex={5}
              groupNestingDepth={detailsFooterProps.groupNestingDepth}
              selectionMode={SelectionMode.single}
              selection={detailsFooterProps.selection}
            />
          );
        }}
        
      />
      
      <Panel
            isOpen={this.state.selectedItem !== null}
            type={PanelType.medium}
            headerText={'Umlauf'}
            onDismiss={()=>this.closeUmlauf()}
            onRenderFooterContent={()=><>
            
            <PrimaryButton 
                    text={'Speichern'}
                    onClick={()=>{
                      this.props.onSave(this.state.selectedItem)
                      this.closeUmlauf();
                    }}
                    disabled={JSON.stringify(this.state.selectedItem) === JSON.stringify(this.state.initSelectedItem)}
                /> <DefaultButton 
                    text={'abbrechen'}
                    onClick={()=>this.closeUmlauf()}
                />
            </>}
        >
          {this.state.selectedItem&&
            <>
                <TextField 
                    label="ID" 
                    disabled 
                    value={this.state.selectedItem?.ID}
                />
                <TextField 
                    label="Bezeichung" 
                    value={this.state.selectedItem?.Text} 
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string)=>this.changeSelectedUmlauf('Text', value)}
                />       
                <SpinEdit 
                    label="Menge" 
                    labelPosition={Position.top}
                    min={0}
                    step={1}
                    precision={0}
                    value={this.state.selectedItem?.Menge} 
                    onChangeCustom={(value:number)=>this.changeSelectedUmlauf('Menge', value)}
                />       
                <SpinEdit 
                    label="Basis" 
                    labelPosition={Position.top}
                    min={0}
                    step={0.01}
                    precision={2}
                    value={this.state.selectedItem?.Basis} 
                    onChangeCustom={(value:number)=>this.changeSelectedUmlauf('Basis', value)}
                />       
            </>
          }
        </Panel>
        </>
    }
    closeUmlauf(){
      let sel = this.umlaufSelection.getSelection();
      if (sel.length > 0){
          this.umlaufSelection.setAllSelected(false)
      } else {
          this.setState({selectedItem:null})
      }
    }
    changeSelectedUmlauf(attr:string, value:any, callback?:()=>void) {
        let Umlauf = {...this.state.selectedItem}
        Umlauf[attr]=value;
        this.setState({selectedItem:Umlauf,
          lastSelection:(new Date).getTime()
        }, callback)
        
    }
}
