export function FormatTime(args:any){
    var vz = '';
    var dauer = args.dauer;
    if (dauer < 0 ){
      vz = '-';
      dauer*=-1;
    }
    if (dauer >= 0){
      var sec = dauer % 60;
      var min = (dauer - sec) / 60 %  60;
      var h = Math.floor((dauer - min * 60 - sec ) / 3600);    
      var result = '';
      if (h < 10){
        result += '0';
      }
      result += h + ':';
      
      if (min < 10){
        result += '0';
      }
      result += min ;
      if (sec > 0){
        result+= ':'
        if (sec < 10){
          result += '0';
        }
        result += sec; 
      }
      result = vz+result;
      var className = "time ";
      if (vz === '-'){
        className+= ' minus';
      } else if (dauer > 0){
        className += ' plus';
      }
      return (<span className={className}>{result}</span>) ;
    } else {
      return '';
    }
  }
  
export function FormatZeit(args:any){
    var date = new Date(args.dauer*1000);
    var h = date.getHours();
    var min = date.getMinutes();
    var sec = date.getSeconds();  
    let cssClass='time';
    if (typeof(args.item) !== 'undefined' 
        && typeof(args.item.gehen) !== 'undefined' && 
        typeof(args.item.gehen.datetime) !== 'undefined' ){
        if (args.item.gehen.datetime ==='NOW'){
          cssClass+=' now';
        }
    }
    return (<span className={cssClass}>{FormatTime({dauer:h*3600+min*60+sec})}</span>);
  }