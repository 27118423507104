import React from 'react';

import { API, Libery } from '../common';

import { IColumn, DetailsList, SelectionMode,DetailsListLayoutMode, ScrollablePane, ScrollbarVisibility, Sticky, StickyPositionType, Text } from '@fluentui/react';
import config from '../config';

const HolidayConfig = {
  apiBase:  '/time/holidays',
}
interface HolidayColumn extends IColumn {
  type:string
}

class Holiday extends React.Component<{
  mode?:string
},{
  items:any[],
  last:number
}> {
  constructor(props:any){
    super(props);

    this.state = {
      items: [],
      last: 0
    }
  }
  componentDidMount(){
    this._getItems();
  } 
  render(){
    if (this.props.mode === 'dashboard'){
      return <>
        <h2><Text variant='xxLarge'>nächster Urlaub</Text></h2>
        <Text variant='large' style={{display:'block'}} >{this.state.items.filter((I:any)=>I.t1 > (new Date).valueOf()/1000).filter((I:any, index:number)=>index===0).map((I:any)=>I.inTagen+'; vom '+config.DatePicker.formatDate(new Date(I.beginn)) +' bis '+config.DatePicker.formatDate(new Date(I.ende)))}</Text>
      </>
    }

    return this.base()
  }

  base() {
    
  let groups = [];
  let last = '';
  for (let i=0; i<this.state.items.length; i++){
    let key=Libery.formatDate(this.state.items[i].t1,'JJJJ');
    if (key !== last) {
      let heute = Libery.formatDate(Math.round((new Date()).getTime()/1000),'JJJJ');
      groups.push({ 
        key: key,
        name: key,
        startIndex:i,
        count: 1,
        isCollapsed:key!==heute
       })
    } else {
      groups[groups.length-1].count++;
    }
    last=key;
}
   const HolidayColumns:HolidayColumn[]=[
              
            {
              key: 'column1',
              name: 'Beginn',
              isResizable: true,
              isPadded: false,
              minWidth:100,
              maxWidth: 100,
              fieldName:'t1',
              type:'date'
            } ,
            {
              key: 'column2',
              name: 'Ende',
              isResizable: true,
              isPadded: false,
              minWidth:100,
              maxWidth: 100,
              fieldName:'t2',
              type:'date'
            } ,
            {
              key: 'column3',
              name: 'Tage',
              isResizable: true,
              isPadded: false,
              minWidth:40,
              maxWidth: 50,
              fieldName: 'tage',
              type:'holidays'
            }  ,
            {
              key: 'column4',
              name: 'Rest',
              isResizable: true,
              isPadded: false,
              minWidth:40,
              maxWidth: 50,
              fieldName: 'rest',
              type:'holidays'
            }  ,
            {
              key: 'column5',
              name: 'in Tagen',
              fieldName:'inTagen',
              isResizable: true,
              isPadded: false,
              minWidth:70,
              maxWidth: 140,
              type:'in days'
            } 
          
          ]
    return (
      <div className="Holiday component">
        <div className="main-view">

        <div className="ScrollFixedHead"> 
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} >
					<DetailsList
						items={this.state.items}
						compact={true}
            groups={groups}
            
						columns={HolidayColumns}
            
						selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            
            onRenderItemColumn={(item:any, index?:number, column?:any)=>{
              let columnType =typeof(column.type)!=='undefined' ? (column.type) : '';
              switch (columnType){
                case 'date':
                  return Libery.formatDatum(item[column.fieldName]);
                case 'days':
                  return 'in ' + item[column.fieldName]+ ' Tagen'
                case 'holidays':
                  return '' + item[column.fieldName]+ ' UT'
                default: 
                
                  return item[column.fieldName];
                
              }
            }}
            onRenderDetailsHeader={(props, defaultRender)=> {
              return (
                <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                  {defaultRender!(props)}
                </Sticky>
              );
            }}
					/>
          </ScrollablePane>
   
        </div>
      </div>
      </div>
    );
    
  }
  //API-Aufruf, um die die Kunden abzurufen
  _getItems(){ 
    API.get(HolidayConfig.apiBase )
      .then( (result) => {
        this.setState({
          items: result
        }); 
    }).catch( (error) => {
      console.error(error);
    });
  }

  
}

export default Holiday;